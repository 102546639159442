var tokenName = 'toyotakuukausitilaus-ui';

export default {
  setToken (token) {
    window.localStorage.setItem(tokenName, token);
  },

  getToken () {
    return window.localStorage.getItem(tokenName);
  },

  removeToken () {
    window.localStorage.removeItem(tokenName);
  }
}
