import Vue from 'vue'
import App from './App.vue'
// import _ from 'lodash';
import router from './router'
import store from './store'
import Token from './helpers/token';
import axios from 'axios'
import VueAxios from 'vue-axios'
import ValueFormat from '@/filters/ValueFormat'
import VueSweetalert2 from 'vue-sweetalert2';
import VueGeolocation from 'vue-browser-geolocation';
import { ModalPlugin, TooltipPlugin } from 'bootstrap-vue'
import VueGtm from 'vue-gtm';
import '@/scss/app.scss'
import VueScrollTo from 'vue-scrollto';

Vue.filter('value', ValueFormat);
Vue.use(VueGeolocation);
Vue.use(ModalPlugin);
Vue.use(TooltipPlugin);
Vue.use(require('vue-moment'));
Vue.use(VueScrollTo);
Vue.use(VueSweetalert2, {
  confirmButtonColor: '#e50000',
  cancelButtonColor: '#f0f0f0'
});

// Custom event dispatching
Vue.prototype.$eventBus = new Vue();

// ========================================================
// Axios setup
// ========================================================
Vue.use(VueAxios, axios);
// Vue.axios.defaults.withCredentials = true;

Vue.axios.interceptors.request.use(config => {
  if (Token.getToken()) {
    config.headers.Authorization = 'Bearer ' + Token.getToken();
  }

  return config;
}, error => {
  return Promise.reject(error);
});

Vue.axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    switch (error.response.status) {
      case 401:
        store.commit('auth/SET_LOGGED_IN', null);
        if (!['Login', 'ForgotPassword', 'Index'].includes(router.currentRoute.name)) {
          router.push({ name: 'Login' });
          break;
        }
    }
    return Promise.reject(error);
  }
);

/**
 * Google tag manager
 */
Vue.use(VueGtm, {
  id: 'GTM-K6WZNC7',
  enabled: process.env.VUE_APP_GTM === 'true', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  debug: process.env.VUE_APP_GTM === 'false', // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
