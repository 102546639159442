<template>
<section class="border-top pt-2 pt-lg-3 pb-2 pb-lg-3">
  <div class="row">
    <div class="col-12 col-md-3 mb-1 mb-md-0">
      <h3>Omat tiedot</h3>
    </div>

    <div class="col-12 col-md-4 col-lg-6">
      <ul class="list-unstyled">
        <li>{{ customer.first_name }} {{ customer.last_name }}</li>
        <li>{{ customer.street_address }}</li>
        <li>{{ customer.zip_code }} {{ customer.post_office }}</li>
        <li>{{ customer.email }}</li>
        <li>{{ customer.phone }}</li>
      </ul>

      <ul class="list-unstyled">
        <li>Käyttäjätunnus: {{ user.email }}</li>
        <li>Salasana: **********</li>
      </ul>
    </div>

    <div class="col-12 col-md-4 col-lg-3">
      <button v-b-modal.editCustomerInfo class="btn btn-block btn-md text-center">Muokkaa</button> <br/>
      <button v-b-modal.editPassword class="btn btn-block btn-md text-center">Vaihda salasana</button>
    </div>

    <ModalEditCustomerInfo />
    <ModalEditPassword />
  </div>
</section>
</template>

<script>

import { mapState } from 'vuex';
import ModalEditCustomerInfo from './partials/ModalEditCustomerInfo'
import ModalEditPassword from './partials/ModalEditPassword'

export default {
  name: 'CustomerInfo',

  components: {
    ModalEditCustomerInfo,
    ModalEditPassword
  },

  computed: {
    ...mapState({
      init: state => state.init,
      customer: state => state.customer.customer,
      user: state => state.auth.me
    })
  }
}
</script>
