<template>
  <div id="header">
    <div class="container-fluid px-lg-4">
      <div class="row py-2">
        <div class="col-4 col-sm-3">
          <div class="toyota-logo">
            <router-link to="/">
              <template v-if="isCustomerPage">
                <svg preserveAspectRatio="xMidYMid" width="60px" height="39px" viewBox="0 0 60 39">
                  <path style=" stroke:none;fill-rule:nonzero;fill:#fff;fill-opacity:1;" d="M 30 0.269531 C 13.585938 0.269531 0.277344 8.878906 0.277344 19.496094 C 0.277344 30.117188 13.585938 38.730469 30 38.730469 C 46.414062 38.730469 59.722656 30.121094 59.722656 19.496094 C 59.722656 8.871094 46.414062 0.269531 30 0.269531 Z M 30 30.476562 C 27.558594 30.476562 25.566406 25.667969 25.441406 19.617188 C 26.910156 19.746094 28.4375 19.816406 30 19.816406 C 31.5625 19.816406 33.089844 19.746094 34.558594 19.617188 C 34.433594 25.664062 32.441406 30.476562 30 30.476562 Z M 25.742188 14.8125 C 26.40625 10.535156 28.0625 7.5 30 7.5 C 31.9375 7.5 33.59375 10.535156 34.257812 14.8125 C 32.894531 14.933594 31.472656 15 30 15 C 28.527344 15 27.105469 14.933594 25.742188 14.8125 Z M 30 3.050781 C 26.8125 3.050781 24.09375 7.824219 23.089844 14.488281 C 17.046875 13.546875 12.828125 11.398438 12.828125 8.902344 C 12.828125 5.535156 20.515625 2.804688 30 2.804688 C 39.484375 2.804688 47.171875 5.535156 47.171875 8.902344 C 47.171875 11.398438 42.953125 13.546875 36.910156 14.488281 C 35.90625 7.824219 33.1875 3.050781 30 3.050781 Z M 4.539062 18.777344 C 4.539062 15.519531 5.792969 12.460938 7.976562 9.835938 C 7.953125 10.019531 7.9375 10.207031 7.9375 10.394531 C 7.9375 14.5 14.09375 18 22.683594 19.289062 C 22.675781 19.585938 22.675781 19.886719 22.675781 20.1875 C 22.675781 27.839844 24.820312 34.320312 27.785156 36.519531 C 14.757812 35.734375 4.539062 28.089844 4.539062 18.777344 Z M 32.214844 36.519531 C 35.179688 34.320312 37.324219 27.839844 37.324219 20.1875 C 37.324219 19.882812 37.320312 19.585938 37.316406 19.289062 C 45.902344 18 52.0625 14.503906 52.0625 10.394531 C 52.0625 10.207031 52.046875 10.019531 52.023438 9.835938 C 54.207031 12.46875 55.460938 15.519531 55.460938 18.777344 C 55.460938 28.089844 45.242188 35.734375 32.214844 36.519531 Z M 32.214844 36.519531 "/>
                </svg>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" width="60px" height="39px" viewBox="0 0 60 39">
                  <path style=" stroke:none;fill-rule:nonzero;fill:#000;fill-opacity:1;" d="M 30 0.269531 C 13.585938 0.269531 0.277344 8.878906 0.277344 19.496094 C 0.277344 30.117188 13.585938 38.730469 30 38.730469 C 46.414062 38.730469 59.722656 30.121094 59.722656 19.496094 C 59.722656 8.871094 46.414062 0.269531 30 0.269531 Z M 30 30.476562 C 27.558594 30.476562 25.566406 25.667969 25.441406 19.617188 C 26.910156 19.746094 28.4375 19.816406 30 19.816406 C 31.5625 19.816406 33.089844 19.746094 34.558594 19.617188 C 34.433594 25.664062 32.441406 30.476562 30 30.476562 Z M 25.742188 14.8125 C 26.40625 10.535156 28.0625 7.5 30 7.5 C 31.9375 7.5 33.59375 10.535156 34.257812 14.8125 C 32.894531 14.933594 31.472656 15 30 15 C 28.527344 15 27.105469 14.933594 25.742188 14.8125 Z M 30 3.050781 C 26.8125 3.050781 24.09375 7.824219 23.089844 14.488281 C 17.046875 13.546875 12.828125 11.398438 12.828125 8.902344 C 12.828125 5.535156 20.515625 2.804688 30 2.804688 C 39.484375 2.804688 47.171875 5.535156 47.171875 8.902344 C 47.171875 11.398438 42.953125 13.546875 36.910156 14.488281 C 35.90625 7.824219 33.1875 3.050781 30 3.050781 Z M 4.539062 18.777344 C 4.539062 15.519531 5.792969 12.460938 7.976562 9.835938 C 7.953125 10.019531 7.9375 10.207031 7.9375 10.394531 C 7.9375 14.5 14.09375 18 22.683594 19.289062 C 22.675781 19.585938 22.675781 19.886719 22.675781 20.1875 C 22.675781 27.839844 24.820312 34.320312 27.785156 36.519531 C 14.757812 35.734375 4.539062 28.089844 4.539062 18.777344 Z M 32.214844 36.519531 C 35.179688 34.320312 37.324219 27.839844 37.324219 20.1875 C 37.324219 19.882812 37.320312 19.585938 37.316406 19.289062 C 45.902344 18 52.0625 14.503906 52.0625 10.394531 C 52.0625 10.207031 52.046875 10.019531 52.023438 9.835938 C 54.207031 12.46875 55.460938 15.519531 55.460938 18.777344 C 55.460938 28.089844 45.242188 35.734375 32.214844 36.519531 Z M 32.214844 36.519531 "/>
                </svg>
              </template>
            </router-link>
          </div>
        </div>
        <div class="col-12 col-sm-6 order-3 order-sm-2 mt-1 mt-sm-0 text-center title">Toyota Kuukausitilaus</div>
        <div class="col-8 col-sm-3 order-2 order-sm-3 text-right">
          <template v-if="isAuthenticated">
            <a v-if="hasMultipleOrders" @click="profile" class="cursor-pointer text-decoration-none mr-2">Tili</a>
            <a @click="logout" class="cursor-pointer text-decoration-none">Kirjaudu ulos</a>
          </template>
          <template v-else>
            <router-link :to="{ name: 'Login' }" class="text-decoration-none">Kirjaudu sisään</router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Header',

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    }),

    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    }),

    hasMultipleOrders () {
      return this.customer.orders > 1;
    },

    isCustomerPage () {
      return this.$route.name === 'Customer';
    }
  },

  methods: {
    logout () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'Login' });
      });
    },

    profile () {
      this.$router.push({ name: 'Customer' });
    }
  }
}
</script>
