<template>
  <section class="pt-2 order-content">
    <template v-if="order">
      <!-- Order info -->
      <h3 class="text-center">Valitsemasi auto</h3>
      <div class="container">
        <div class="row mt-2">
          <div class="col-12 text-center">
            <strong>{{ order_car.product_car.display_name }}</strong>, noutopaikka <strong>{{ order.dealer.display_name }}.</strong><br/>
            Kuukausihinta <strong>{{ order_car.price | value(0) }}{{'\xa0'}}€/kk </strong><span v-if="order_car.product_car.retail_price && order_car.price !== order_car.product_car.retail_price">(1kk jälkeen {{ order_car.product_car.retail_price | value(0) }}{{'\xa0'}}€/kk)</span>.
            Tilauksen kesto on toistaiseksi voimassa oleva (minimikäyttöaika 1kk).
          </div>
        </div>

        <!-- Order start -->
        <template v-if="!isExpired">
          <h3 class="text-center mt-3">Milloin haluat hakea auton?</h3>

          <div class="row mt-2">
            <div class="col-12 text-center">
              <div class="d-sm-inline-block  mx-auto" style="width: 200px;">
                <Datepicker
                  :input-class="{'form-control': true}"
                  v-model="start_date_input"
                  format="d.M.yyyy"
                  :mondayFirst="true"
                  :calendarButton="true"
                  :calendarButtonIcon="form.busy ? 'fa fa-spinner fa-spin' : 'icon-calendar'"
                  :language="fi"
                  :fullMonthName="true"
                  :disabledDates="getDisabledDates"
                  placeholder="Valitse"
                  @input="updateStartDate"
                  :class="{'disabled': form.busy}"
                />

              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="text-center mt-3" v-if="isExpired">
        <button @click="updateOrder" class="btn btn-t1-primary">Hae auton saatavuus uudelleen</button>
      </div>
    </template>

    <template v-else>
      <div class="text-center py-3">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>

  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'
import Datepicker from 'vuejs-datepicker';
import { fi } from 'vuejs-datepicker/dist/locale'

export default {
  name: 'OrderService',
  components: {
    Datepicker
  },

  data () {
    return {
      fi: fi,

      start_date_input: null,
      form: new Form({
        start_date: null
      })
    }
  },

  computed: {
    ...mapState({
      order: state => state.order.order,
      order_car: state => state.order.order.order_product_car,
      disabled_dates: state => state.disabled_order_dates
    }),

    isExpired () {
      return this.order && this.order.status === 'expired';
    },

    getDisabledDates () {
      var dates = this.disabled_dates;
      dates.to = this.order.available_from_date ? this.$moment(this.order.available_from_date).toDate() : this.$moment(this.order.start_date).toDate();
      return dates;
    }
  },

  methods: {
    updateOrder () {
      this.$bvModal.show('updateNewOrder');
    },

    updateStartDate () {
      this.form.start_date = this.$moment(this.start_date_input).format('Y-M-D');
      if (this.form.start_date === this.order.start_date) return;

      this.$store.dispatch('order/updateStartDate', this.form).then(() => {
        // TODO
      }).catch(error => {
        this.start_date_input = this.order.start_date;

        if (error.response.status === 410) {
          this.$swal({
            title: 'Noutopäivän valinta epäonnistui',
            icon: 'error',
            text: 'Haluamasi noutopäivä ei ole mahdollinen. Ole hyvä, ja valitse toinen päivämäärä.'
          });
        } else {
          this.$swal({
            title: 'Noutopäivän valinta epäonnistui',
            icon: 'error',
            text: 'Ole hyvä ja yritä uudelleen. Jos päivämäärän muutos ei vieläkään onnistu, ota yhteyttä ylläpitoon.'
          });
        }
      });
    }
  },

  mounted () {
    this.start_date_input = this.order.start_date;
  }
}
</script>
