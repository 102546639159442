<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>


<script>
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },

  created () {
    window.location.href = process.env.VUE_APP_PROMO_SITE;
    this.$store.dispatch('initApplication');
  }
}
</script>
