import Vue from 'vue'
import Token from '@/helpers/token';
import router from '@/router/'

export default {
  namespaced: true,

  state: {
    loading: false,
    order: null,
    selected_extra_services: []
  },

  mutations: {
    SET_LOADING: (state, payload) => {
      state.loading = payload;
    },

    SET_ORDER: (state, payload) => {
      state.order = payload;
      state.selected_extra_services = payload.order_extra_services;
    }
  },

  actions: {
    async createNewCustomerWithOrder ({ dispatch, commit, rootState }, form) {
      try {
        // Add model selection to form data
        form.car = rootState.models.selected;
        // Create new order
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/createNewOrder');

        // Set token and fetch user info
        Token.setToken(response.data.token);
        await dispatch('auth/fetchMe', null, { root: true });

        dispatch('models/resetAvailability', null, { root: true });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createNewOrder ({ dispatch, commit, rootState }) {
      try {
        // Add model selection to form data
        var form = {
          car: rootState.models.selected
        };

        // Create new order
        await Vue.axios.post(process.env.VUE_APP_BACKEND_API + '/order/createNewOrder', form);

        dispatch('models/resetAvailability', null, { root: true });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async sendLoginToken ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/user/sendLoginToken');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateNewOrder ({ dispatch, commit, rootState, state }, form) {
      try {
        // Add model selection to form data
        form.car = rootState.models.selected;
        form.order = { order_number: state.order.order_number };

        // Update new order
        await form.post(process.env.VUE_APP_BACKEND_API + '/order/updatePendingOrder');

        // Fetch updated order
        dispatch('getOrder');

        dispatch('models/resetAvailability', null, { root: true });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getOrder ({ dispatch, commit, rootState }, payload) {
      var showLoader = !payload || payload.showLoader !== false;

      try {
        if (showLoader) commit('SET_LOADING', true);
        var order = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/order/getOrder');
        commit('SET_ORDER', order.data.order);
        commit('customer/SET_CUSTOMER', order.data.customer, { root: true });
        if (showLoader) commit('SET_LOADING', false);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateStartDate ({ state, dispatch, commit }, form) {
      try {
        form.order_number = state.order.order_number;
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/updateStartDate');
        commit('SET_ORDER', response.data.order);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async acceptTermsAndServices ({ state, dispatch, commit }, form) {
      try {
        form.order_number = state.order.order_number;
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/acceptTermsAndServices');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateExtraService ({ state, dispatch, commit }, form) {
      try {
        form.order_number = state.order.order_number;
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/updateExtraService');
        // Get updated order
        await dispatch('getOrder', { showLoader: false });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createIdentification ({ dispatch, commit }) {
      try {
        var identification = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/order/createIdentification');
        return Promise.resolve(identification.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async checkForDriversLicense ({ dispatch, commit }) {
      try {
        var response = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/order/checkDriversLicenseAndCredit');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async confirmDriversLicense ({ dispatch, commit }, form) {
      try {
        await form.post(process.env.VUE_APP_BACKEND_API + '/order/confirmDriversLicense');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async addCreditCard ({ dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/payments/registerPaymentCard');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async confirmOrder ({ state, dispatch, commit }, form) {
      try {
        form.order_number = state.order.order_number;
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/confirmOrder');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async setPassword ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/setPassword');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async setActivePaymentCard ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/setActivePaymentCard');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async setPickupTime ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/setPickupTime');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async deletePaymentCard ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/deletePaymentCard');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async cancelOrder ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/cancelOrder');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async continueOrder ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/continueOrder');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async purchaseExtraService ({ state, dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/purchaseExtraService');
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getReceipt (context, form) {
      return new Promise((resolve, reject) => {
        Vue.axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND_API + '/order/getReceipt',
          data: form,
          responseType: 'blob'
        }).then(response => {
          // Parse filename from headers
          var filename = false;
          var disposition = response.headers['content-disposition'];

          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          if (!filename) filename = 'toyota-kuukausitilaus-kuitti-' + form.invoice_number + '.pdf';

          // IE fix
          if (window.navigator.msSaveOrOpenBlob) {
            var blobObject = new Blob([response.data]);
            window.navigator.msSaveOrOpenBlob(blobObject, filename);
          } else {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = window.URL.createObjectURL(response.data);
            link.download = filename;
            link.click();
          }

          return resolve();
        }).catch(error => {
          readBlobError(error.response.data).then(response => {
            return reject(response);
          })
        });
      });
    },

    downloadTerms (context, params) {
      return new Promise((resolve, reject) => {
        Vue.axios({
          method: 'get',
          url: params.url,
          responseType: 'blob'
        }).then(response => {
          // Parse filename from headers
          var filename = false;
          var disposition = response.headers['content-disposition'];
          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }

          if (!filename) filename = 'toyota-kuukausitilaus-sopimusehdot-' + params.order_number + '.pdf';

          // IE fix
          if (window.navigator.msSaveOrOpenBlob) {
            var blobObject = new Blob([response.data]);
            window.navigator.msSaveOrOpenBlob(blobObject, filename);
          } else {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = window.URL.createObjectURL(response.data);
            link.download = filename;
            link.click();
          }

          return resolve();
        }).catch(error => {
          readBlobError(error.response.data).then(response => {
            return reject(response);
          })
        });
      });
    },

    async redirectNewOrder ({ state, rootState, dispatch }) {
      // Fetch order
      try {
        await dispatch('order/getOrder', null, { root: true });
      } catch (error) {
        console.error(error);
      }

      var customer = rootState.customer.customer;
      var redirect = false;

      // Check order state and redirect
      if (state.order.status === 'expired' || state.order.status === 'removed') redirect = routerPush('NewOrder.SelectExtraServices');
      else if (state.order.status === 'confirmed' || state.order.status === 'delivered') redirect = routerPush('NewOrder.OrderComplete');
      else if (!state.order.contract_terms) redirect = routerPush('NewOrder.SelectExtraServices');
      else if (!customer.identified || !customer.has_drivers_license || !customer.has_credit_rating) redirect = routerPush('NewOrder.Identify');
      else if (!state.order.payment_cards || !state.order.payment_cards.length) redirect = routerPush('NewOrder.PaymentInfo');
      else if (state.order.status !== 'confirmed') redirect = routerPush('NewOrder.ConfirmOrder');

      return redirect;
    }
  }
}

function readBlobError (blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', function () {
      if (reader.result.charAt(0) !== '{') return resolve(new Error('Tuntematon virhe'));
      return resolve(JSON.parse(reader.result));
    });
    reader.readAsText(blob);
  });
}

function routerPush (routeName) {
  if (routeName === router.currentRoute.name) return false;
  router.push({ name: routeName });
  return true;
}
