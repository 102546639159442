<template>
  <b-modal
    :ref="modalId"
    :id="modalId"
    size="md"
    title="Lisää uusi maksukortti"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :footer-class="'justify-content-center'"
    >

    <div class="text-center">
      <p>Voit lisätä uuden maksukortin alla olevasta napista. Maksukortin voi asettaa aktiiviseksi tilaussivun Maksukortit-kohdan Aktiivinen-sarakkeesta. Veloitukset tehdään aina aktiivisesta maksukortista.</p>
      <button @click="addCard" class="btn btn-t1-primary mt-2" :class="{'disabled': form.busy}">
        <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0"></i>
        Lisää maksukortti
      </button>
    </div>


    <template v-slot:modal-footer="{ ok, cancel}">
      <button class="btn btn-t1-default" @click="cancel()">
        Peruuta
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'ModalAddPaymentCard',

  props: ['order'],

  data () {
    return {
      error: null,

      form: new Form({
        order_number: '',
        new_order: false
      })
    }
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    }),

    modalId () {
      return 'addPaymentCard-' + this.order.order_number;
    }
  },

  methods: {
    addCard () {
      this.form.order_number = this.order.order_number;
      this.$store.dispatch('order/addCreditCard', this.form).then(response => {
        if (response.data.terminal_url) {
          window.location.href = response.data.terminal_url;
        }
      }).catch(error => {
        console.log(error);
        this.$swal({
          title: 'Virhe maksukortin lisäyksessä',
          text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
        });
      });
    },

    cancel () {
      this.$bvModal.hide(this.modalId);
    }
  }
}
</script>
