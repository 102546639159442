<template>
  <div class="page">
    <div class="page-container py-2 py-md-3 px-1 px-lg-3 bg-white">


      <template v-if="!customer">
        <div class="text-center">
          <i class="fa fa-spinner fa-spin pr-0 text-lg"></i>
        </div>
      </template>

      <template v-else-if="!customer.user_password_set">
        <CustomerSetPassword />
      </template>

      <template v-else>
        <div class="pb-2 pb-md-3 d-flex">
          <h1>Tili</h1>
          <button @click="addNewOrder" class="btn btn-md btn-t1-primary ml-auto">Tee uusi kuukausitilaus</button>
        </div>



        <CustomerInfo />
        <Orders />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CustomerSetPassword from '@/components/customer/CustomerSetPassword';
import CustomerInfo from '@/components/customer/CustomerInfo';
import Orders from '@/components/customer/Orders';

export default {
  name: 'Customer',

  components: {
    CustomerSetPassword,
    CustomerInfo,
    Orders
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    })
  },

  methods: {
    addNewOrder () {
      this.$router.push({ name: 'Index' });
    }
  },

  created () {
    document.body.classList.add('customer');
  },

  mounted () {
    if (typeof this.$route.query.maksukorttivirhe !== 'undefined') {
      this.$swal({
        title: 'Virhe maksukortin lisäyksessä',
        icon: 'error',
        text: 'Maksukortin lisäyksessä tapahtui virhe tai se peruutettiin. Ole hyvä ja yritä uudelleen.'
      });
    } else if (typeof this.$route.query.maksukorttilisatty !== 'undefined') {
      this.$swal({
        title: 'Maksukortti lisätty',
        icon: 'success',
        text: 'Maksukorttisi on lisätty onnistuneesti!'
      });
    }
  },

  beforeDestroy () {
    document.body.classList.remove('customer');
  }
}
</script>
