import Vue from 'vue'
import Token from '@/helpers/token';

export default {
  namespaced: true,

  state: {
    logged_in: false,
    me: null
  },

  getters: {
    isAuthenticated: state => state.logged_in
  },

  mutations: {
    SET_LOGGED_IN: (state, payload) => {
      if (payload) {
        state.me = payload;
        state.logged_in = true;
      } else {
        state.me = null;
        state.logged_in = false;
      }
    }
  },

  actions: {
    async fetchMe ({ commit }) {
      if (!Token.getToken()) return Promise.reject(Error('No token.'));

      try {
        var response = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/user/user');
        commit('SET_LOGGED_IN', response.data.user);
        commit('customer/SET_CUSTOMER', response.data.customer, { root: true });
        if (response.data) return Promise.resolve(true);
        else return Promise.reject(Error('Unauthenticated'));
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async login ({ dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/user/login');
        Token.setToken(response.data.token);
        await dispatch('fetchMe');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async loginWithToken ({ dispatch, commit }, form) {
      try {
        var response = await form.get(process.env.VUE_APP_BACKEND_API + '/user/loginWithToken/' + form.token);
        Token.setToken(response.data.token);
        await dispatch('fetchMe');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async logout ({ commit }) {
      try {
        await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/user/logout');
        commit('SET_LOGGED_IN', null);
        Token.removeToken();
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async forgotPassword (context, form) {
      try {
        await form.post(process.env.VUE_APP_BACKEND_API + '/user/forgot-password');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async resetPassword (context, form) {
      try {
        await form.post(process.env.VUE_APP_BACKEND_API + '/user/reset-password');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
}
