<template>
  <section class="pt-2 px-2 identification-progress">

    <div class="ident-phases text-center">
      <div v-for="(p, idx) in phases" :key="idx">
        <div v-if="p.show" class="phase mb-2 pb-1" :class="'status-' + p.status">
          <h4>
            <i v-if="p.status === 'success'" class="icon-ok text-green"></i>
            <i v-if="p.status === 'error'" class="icon-remove text-red"></i>
            <i v-if="p.status === 'denied'" class="icon-remove text-red"></i>
            {{ p.title }}
            <span v-if="p.status === 'success'">OK</span>
          </h4>

          <div class="bar" :class="'status-' + p.status"><span></span></div>
        </div>
      </div>
    </div>

    <template v-if="onProgress">
      <div class="text-center py-1 pt-2">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
        <p class="mt-1">Suoritetaan tarkastuksia</p>
      </div>
    </template>

    <template v-else>
      <div class="text-center">
        <template v-if="!errors && confirmDriversLicense">
          <h4>Ajo-oikeuden tarkistus</h4>
          <div class="checkbox text-left ml-1 mb-1 mt-1">
            <input id="drivers_license" type="checkbox" v-model="form.drivers_license">
            <label class="ws-normal d-inline-block" for="drivers_license">Vakuutan, että minulla on henkilöauton kuljettamiseen riittävä voimassa oleva ajo-oikeus, jonka voin todistaa noutaessani auton. Sitoudun siihen, että en kuljeta minulle luovutettua ajoneuvoa, jos menetän ajo-oikeuteni.</label>
          </div>
        </template>

        <h5 class="mb-1 pt-1" v-if="message">
          <template v-if="(confirmDriversLicense && form.drivers_license && !errors) || !confirmDriversLicense || errors">{{ message }}</template>
        </h5>

        <ul v-if="errors" class="list-unstyled">
          <li v-if="phases.identification.message"><i class="text-red icon-remove"></i> {{ phases.identification.message }}</li>
          <li v-if="phases.drivers_license.message"><i class="text-red icon-remove"></i> {{ phases.drivers_license.message }}</li>
          <li v-if="phases.credit_rating.message"><i class="text-red icon-remove"></i> {{ phases.credit_rating.message }}</li>
        </ul>

        <template v-if="errors">
          <p class="mt-2">Jos haluat jatkaa tilausprosessia, olethan yhteydessä asiakaspalveluun:</p>
          <!--<p><a :href="contactUrl" target="_blank">Yhteydenottolomake</a></p> -->
          <p><a href="mailto:kuukausitilaus@toyota.fi">kuukausitilaus@toyota.fi</a></p>
          <!-- <p><a href="tel:+358409224777">p. +358 40 922 4777</a></p> -->
        </template>

        <button v-if="!errors" @click="paymentInfo" class="mt-1 btn btn-lg btn-t1-primary" :class="{'disabled': confirmDriversLicense && !form.drivers_license}">
          Lisää maksukortti
        </button>
      </div>
    </template>

  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'
import _ from 'lodash';

export default {
  name: 'IdentificationProgress',

  data () {
    return {
      onProgress: true,
      polling: null,
      errors: false,
      denied: false,

      drivers_license_manual_check: false,

      form: new Form({
        drivers_license: false
      }),

      phases: {
        identification: {
          title: 'Tunnistus',
          status: 'success',
          message: null,
          show: true
        },
        drivers_license: {
          title: 'Ajo-oikeus',
          status: 'created',
          message: null,
          show: false
        },
        credit_rating: {
          title: 'Luottotietojen tarkistus',
          status: null,
          message: null,
          show: true
        }
      }
    }
  },

  computed: {
    ...mapState({
      order: state => state.order.order
    }),

    contactUrl () {
      return process.env.VUE_APP_CONTACT_PAGE;
    },

    confirmDriversLicense () {
      return this.phases.drivers_license.status !== 'success' && this.drivers_license_manual_check;
    }
  },
  methods: {
    paymentInfo () {
      if (this.confirmDriversLicense) {
        this.$store.dispatch('order/confirmDriversLicense', this.form).then(() => {
          this.$router.push({ name: 'NewOrder.PaymentInfo' });
        }).catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Virhe lomakkeen lähetyksessä',
            text: 'Lataa sivu uudelleen ja yritä uudelleen.'
          });
        });
      } else {
        this.$router.push({ name: 'NewOrder.PaymentInfo' });
      }
    },

    startPolling () {
      this.onProgress = true;
      this.polling = setInterval(() => {
        this.axios.get(process.env.VUE_APP_BACKEND_API + '/order/getIdentificationStatus').then(response => {
          // Set statuses
          this.phases.identification.status = response.data.identification;
          this.phases.drivers_license.status = response.data.drivers_license;
          this.phases.credit_rating.status = response.data.credit_rating;

          if (this.phases.drivers_license.status === 'success') this.phases.drivers_license.show = true;

          // End polling- if all statuses success/error/pending/denied/manual
          if (!_.findKey(this.phases, function (o) { return !['success', 'error', 'pending', 'denied', 'manual'].includes(o.status) })) {
            this.endPolling();
            this.pollingFinished();
          }
        }).catch(() => {
          // Ok
        });
      }, 1500);
    },

    endPolling () {
      clearInterval(this.polling);
      this.onProgress = false;
    },

    pollingFinished () {
      if (this.phases.identification.status === 'error') {
        this.errors = true;
        this.phases.identification.message = 'Virhe tunnistautumisessa'
      }

      if (this.phases.drivers_license.status === 'error') {
        this.errors = true;
        this.phases.drivers_license.message = 'Virhe ajo-oikeuden tarkistuksessa'
      }

      if (this.phases.credit_rating.status === 'error') {
        this.errors = true;
        this.phases.credit_rating.message = 'Virhe luottotietojen tarkistuksessa'
      } else if (this.phases.credit_rating.status === 'pending') {
        this.errors = true;
        this.phases.credit_rating.message = 'Luottotietojen tarkistuksessa tapahtui virhe. Käsittelyn kesto voi olla noin 5-10 minuuttia.';
      } else if (this.phases.credit_rating.status === 'denied') {
        this.denied = true;
        this.errors = true;
        this.phases.credit_rating.message = 'Luottotietojen tarkistus on hylätty';
      }

      if (this.errors) {
        this.message = 'Tunnistautumisessa tapahtui seuraavia virheitä:'
      } else {
        this.message = 'Tunnistautuminen OK. Lisää seuraavaksi maksukorttisi.'
      }
    }
  },

  mounted () {
    this.$store.dispatch('order/checkForDriversLicense').then(response => {
      if (response.data.drivers_license && response.data.drivers_license === 'check') {
        this.phases.drivers_license.show = true;
      } else if (response.data.drivers_license && response.data.drivers_license === 'manual') {
        this.drivers_license_manual_check = true;
      }
    });
    this.startPolling();
  },

  beforeDestroy () {
    this.endPolling();
  }
}
</script>
