<template>
  <b-modal
    :ref="modalId"
    :id="modalId"
    size="md"
    title="Osta lisäkilometripaketti"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :footer-class="'justify-content-center'"
    @hide="cancel(false)"
    >

    <div>
      <p>Valitse haluamasi lisäkilometripaketti ja suorita maksu. Maksu veloitetaan välittömästi tilauksen aktivoidulta maksukortilta.</p>
    </div>

    <div>
      <div v-for="(item, idx) in order.available_extra_services" :key="idx">
        <button @click="selectService(item.id)" class="btn btn-block mb-1" :class="{'btn-t1-primary': form.service_id === item.id}" >
          <i class="fa" style="width: 25px" :class="{'fa-square-o': form.service_id !== item.id, 'fa-check': form.service_id === item.id }"></i>
          <span>{{ item.service_name }}</span>
          <span v-if="item.display_description" class="icon-info-sign pl-1" v-b-tooltip="item.display_description"></span>
          <strong class="float-right pl-1">{{ item.display_price | value(2) }}{{'\xa0'}}€</strong>
        </button>
      </div>
    </div>

    <template v-slot:modal-footer="{ ok, cancel }">
      <button class="btn btn-t1-primary" @click="submitForm" :class="{'disabled': form.busy || !form.service_id}">
        <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
        Suorita maksu
      </button>

      <button class="btn btn-t1-default" @click="cancel(false)">
        Sulje
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'ModalPurchaseExtraServices',

  props: ['order'],

  data () {
    return {
      error: null,

      form: new Form({
        order_number: '',
        service_id: null
      })
    }
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    }),

    modalId () {
      return 'purchaseExtraServices-' + this.order.order_number;
    }
  },

  methods: {
    selectService (id) {
      this.form.service_id = this.form.service_id !== id ? id : null;
    },

    async submitForm () {
      this.form.order_number = this.order.order_number;

      var confirm = await this.$swal({
        text: 'Haluatko varmasti ostaa lisäkilometripaketin? Veloitus suoritetaan välittömästi maksukortiltasi.',
        showCancelButton: true,
        cancelButtonText: 'Peruuta',
        confirmButtonText: 'Kyllä'
      });

      if (!confirm.isConfirmed) return;

      this.$store.dispatch('order/purchaseExtraService', this.form).then(response => {
        this.$swal({
          title: 'Lisäkilometripaketti ostettu',
          icon: 'success'
        });
        this.cancel(true);
      }).catch(() => {
        this.$swal({
          title: 'Virhe lisäkilometripaketin ostossa.',
          icon: 'error',
          text: 'Ole hyvä, päivitä sivu, varmista että maksukorttisi on voimassa ja yritä uudelleen. Jos kilometripaketin ostaminen ei onnistu vieläkään, ole hyvä ja ota yhteyttä ylläpitoon.'
        });
      });
    },

    cancel (reload) {
      if (reload) this.$store.dispatch('customer/getOrders');
      this.form.reset();
      this.$bvModal.hide(this.modalId);
    }
  }
}
</script>
