<template>
  <b-modal
    ref="searchAgentForm"
    id="searchAgentForm"
    size="md"
    title="Kertokaa minulle, kun autoja on saatavilla"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :footer-class="'justify-content-center'"
    >

    <div class="row">
      <div class="col-12">
        Anna yhteystietosi, niin lähetämme sinulle viestin, kun hakuehtojesi mukaisia autoja on taas vapaana. Saat meiltä maksimissaan yhden viestin kerran päivässä. Voit milloin tahansa peruuttaa viestit klikkaamalla viestissä olevaa linkkiä.
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-sm-2">Sijainti:</div>
      <div class="col-12 col-sm-10 pl-2"><strong>{{ searchForm.location }}</strong> ({{ searchForm.distance }}km etäisyydellä)</div>
    </div>

    <div class="row" style="padding-top: 5px;">
      <div class="col-12 col-sm-2">Automallit:</div>
      <div class="col-12 col-sm-10 bold pl-2">{{ getModelsString }}</div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-2" style="padding-top: 5px;">Vaihteisto:</div>
      <div class="col-12 col-sm-10">
        <div class="d-inline-block select-filter-wrapper">
          <select v-model="gear_type" class="select-filter">
            <option value="">Ei väliä</option>
            <option value="A">Automaatti</option>
            <option value="M">Manuaali</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-md-6">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('first_name') }">
          <label for="first_name">Etunimi *</label>
          <input type="text" v-model="form.first_name" class="form-control" id="first_name">
        </fieldset>
      </div>

      <div class="col-12 col-md-6">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('last_name') }">
          <label for="last_name">Sukunimi *</label>
          <input type="text" v-model="form.last_name" class="form-control" id="last_name">
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('email') }">
          <label for="street_address">Sähköpostiosoite *</label>
          <input type="text" v-model="form.email" class="form-control" id="email">
          <small class="text-muted validation-error--message">{{ form.errors.get('email') }}</small>
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('phone') }">
          <label for="phone">Puhelinnumero</label>
          <input type="text" v-model="form.phone" class="form-control" id="phone">
          <small class="text-muted validation-error--message">{{ form.errors.get('phone') }}</small>
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('expire_at')}">
          <label for="city">Haluan saada viestejä *</label>
          <select v-model="form.expire_at">
            <option value="0">Valitse</option>
            <option value="1">Viikon ajan</option>
            <option value="2">2 viikon ajan</option>
            <option value="4">Kuukauden ajan</option>
            <option value="8">2 kuukauden ajan</option>
          </select>
        </fieldset>
      </div>

      <!-- accept terms -->
      <div class="col-12 mt-1">
        <div class="checkbox">
          <input id="accept_terms" type="checkbox" v-model="form.accept_terms">
          <label class="ws-normal" for="accept_terms">Hyväksyn Toyota-verkkosivujen käyttöehdot ja tietosuojakäytännöt</label>
        </div>

        <div style="padding-left: 30px;">
          <p>Annettuja henkilötietoja käytetään vain Toyota Kuukausitilaus -palvelun hallinnoimiseen.<br/>
          <a class="cursor-pointer" @click="show_terms = !show_terms">
            <template v-if="!show_terms"><i class="icon-chevron-down"></i> <u>Näytä ehdot</u></template>
            <template v-else><i class="icon-chevron-up"></i> <u>Piilota ehdot</u></template>
          </a>
          </p>


          <div v-if="show_terms">
            <p>Toyota Auto Finland Oy, Korpivaarantie 1, 01450 Vantaa, kerää rekisterinpitäjänä henkilötietoja Toyota Kuukausitilaus -palvelun asiakkaista ja uutiskirjetilaajista. Kun lähetät yhteydenoton tämän sivun kautta, yhteydenottoasi koskevat seuraavat säännöt:</p>

            <ul>
              <li>Käytämme näitä henkilötietoja vain pyyntöjesi toteuttamiseen ja seurantaan.</li>
              <li>Edellä mainitut ehdot täydentävät Toyotan yleistä tietosuojakäytäntöä, jossa kuvataan henkilötietojen käsittelyn yleiset suuntaviivat ja jotka sinun on luettava ja hyväksyttävä.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

     <template v-slot:modal-footer="{ ok, cancel}">

        <button class="btn btn-t1-primary" @click="saveForm" :class="{'disabled': form.busy || !form.accept_terms}">
          <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
          Tallenna
        </button>
        <button class="btn btn-t1-default" @click="cancel()">
          Peruuta
        </button>

      </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'SearchAgentFormModal',

  data () {
    return {
      error: null,
      show_terms: false,
      gear_type: '',

      form: new Form({
        accept_terms: false,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        expire_at: 0,
        search: null
      })
    }
  },

  computed: {
    ...mapState({
      searchData: state => state.models.searchData,
      searchForm: state => state.models.form,
      models: state => state.models.models
    }),

    getModelsString () {
      var displayNames = [];
      for (var m in this.searchForm.models) {
        const model = this.models.find(el => el.model === this.searchForm.models[m]);
        displayNames.push(model.display_name);
      }
      return displayNames.length ? displayNames.filter(Boolean).join(', ') : 'Kaikki';
    }
  },

  methods: {
    saveForm () {
      this.form.search = {
        location: this.searchData.location,
        coordinates: this.searchData.geocode.location,
        models: this.searchForm.models,
        distance: this.searchForm.distance,
        gearType: this.gear_type
      };

      this.$store.dispatch('searchAgent/save', this.form).then(() => {
        this.$swal({
          title: 'Hakuvahti tallennettu',
          icon: 'success'
        });

        this.$gtm.trackEvent({
          event: 'emailAlert',
          category: 'Toyota Kuukausitilaus',
          action: 'Hakuvahti',
          label: 'Lähetys'
        });
        this.form.reset();
        this.cancel();
      }).catch(error => {
        if (error.response.status === 422) return;

        this.$swal({
          title: 'Virhe hakuvahdin tallennuksessa',
          icon: 'error'
        });
      });
    },

    cancel () {
      this.$bvModal.hide('searchAgentForm');
    }
  },

  mounted () {
    this.gear_type = this.searchForm.gearType;
  }
}
</script>
