<template>
  <div class="page">
    <div class="login bg-white py-3 px-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3 class="bold">Salasanan vaihto</h3>
          </div>

          <div class="col-12">
            <p>Salasanan tulee sisältää vähintään 10 merkkiä, yhden ison kirjaimen ja yhden numeron.</p>
          </div>
        </div>
        <div v-if="form.errors.has('error')" class="bg-message p-1 mt-2 error normal">{{ form.errors.get('error') }}</div>

        <div v-on:keyup.enter="login">
          <div class="row mt-2">
            <div class="col-12">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('email') }">
                <label for="email">Sähköpostiosoite</label>
                <input v-model="form.email" type="email" class="form-control" id="email">
                <small class="text-muted validation-error--message">{{ form.errors.get('email') }}</small>
              </fieldset>
            </div>

            <div class="col-12">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('password') }">
                <label for="password">Uusi salasana</label>
                <input v-model="form.password" type="password" class="form-control" id="password">
                <small class="text-muted validation-error--message">{{ form.errors.get('password') }}</small>
              </fieldset>
            </div>

            <div class="col-12">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('password_confirmation') }">
                <label for="password">Salasana uudelleen</label>
                <input v-model="form.password_confirmation" type="password" class="form-control" id="password_confirmation">
                <small class="text-muted validation-error--message">{{ form.errors.get('password_confirmation') }}</small>
              </fieldset>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 col-sm-6 text-right order-sm-2 mb-1">
              <button @click="reset" class="btn btn-t1-primary" :class="{'disabled': form.busy}"><i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>Vaihda salasana</button>
            </div>
            <div class="col-12 col-sm-6 order-sm-1 mt-1">
              <router-link :to="{name: 'Login'}">Kirjaudu sisään</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vform'

export default {
  name: 'ResetPassword',

  data () {
    return {
      form: new Form({
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
      })
    }
  },

  mounted () {
    this.form.token = this.$route.params.token;
  },

  methods: {
    reset () {
      this.$store.dispatch('auth/resetPassword', this.form).then(() => {
        this.$swal({ text: 'Salasanasi on vaihdettu. Voit nyt kirjautua sisään uudella salasanalla.' });
        this.$router.push({ name: 'Login' });
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$swal({
            title: 'Virhe salasanan vaihdossa',
            text: 'Tarkasta sähköpostiosoite ja yritä uudelleen.',
            icon: 'error'
          });
        }
      });
    }
  }
}
</script>
