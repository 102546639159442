<template>
  <div class="row justify-content-center pt-2 mt-1">
    <div class="col-12 col-md-10 border-top pt-2">
      <h3>Toyota Kuukausitilauksesi yhteenveto</h3>


      <div class="row mt-2 pb-2 border-bottom">
        <div class="col-12 col-md-6">
          {{ order_car.product_car.display_name }} {{ order.geartype ? '(' + order.geartype + ') ' : '' }}<br/>
          Tilaus voimassa toistaiseksi.
        </div>
        <div class="col-12 col-md-6 text-md-right">{{ order_car.price | value(0) }}{{'\xa0'}}€/kk <span v-if="order_car.product_car.retail_price && order_car.price !== order_car.product_car.retail_price">(1kk jälkeen {{ order_car.product_car.retail_price | value(0) }}{{'\xa0'}}€/kk)</span></div>
      </div>

      <div v-if="order.order_extra_services && order.order_extra_services.length">
        <div class="row mt-2">
          <div class="col-12"><strong>Lisäpalvelut</strong></div>
        </div>

        <div class="py-1 border-bottom" v-for="(es, idx) in order.order_extra_services" :key="idx">
          <div class="row">
            <div class="col-12 col-md-8">
              {{ es.service_name }}
              <div v-if="es.additional_info" class="row">
                <template v-if="es.service_key === 'home_delivery'">
                  <div class="col-12">Auto tuodaan {{ order.start_date | moment('D.M.Y') }}</div>
                  <div class="col-12">Toimitusosoite: {{ es.additional_info.address }}, {{ es.additional_info.zip }}, {{ es.additional_info.city }}</div>
                  <div class="col-12">Toimitusaikatoive: {{ es.additional_info.delivery_time }}</div>
                </template>
              </div>
            </div>
            <div class="col-12 col-md-4 text-md-right">Kertaveloitus<br/> {{ es.price | value(2) }}{{'\xa0'}}€</div>
          </div>


        </div>
      </div>

      <div class="row mt-2 pb-2 border-bottom">
        <div class="col-12 col-sm-4"><strong>Auton toimittaja</strong></div>

        <div class="col-12 col-sm-8 mt-1 mt-sm-0 text-sm-right">
          {{ order.dealer.display_name }}<br/>
          {{ order.dealer.address }}, {{ order.dealer.zip }} {{ order.dealer.city }}<br/>
        </div>
      </div>

      <div class="row mt-2 pb-2 border-bottom">
        <div class="col-12 col-sm-4"><strong>{{ order.has_home_delivery ? 'Toimituspäivä': 'Noutopäivä' }}</strong></div>
        <div class="col-12 col-sm-8 mt-1 mt-sm-0 text-sm-right">{{ order.start_date | moment('D.M.Y') }}</div>
      </div>

      <div class="row mt-2 pb-2 border-bottom">
        <div class="col-12 col-sm-4"><strong>Palvelun tuottaa</strong></div>

        <div class="col-12 col-sm-8 mt-1 mt-sm-0 text-sm-right">
         Toyota Auto Finland Oy, Y-tunnus 1019670-5<br/>
         Toyota Finance Finland Oy, Y-tunnus 1019666-8
        </div>
      </div>

      <div class="row mt-2 pb-2 border-bottom">
        <div class="col-12 col-sm-6"><strong>Toyota Kuukausitilaus sopimusehdot</strong></div>

        <div class="col-12 col-sm-6 mt-1 mt-sm-0 text-sm-right">
          <a class="link cursor-pointer no-decoration" @click="downloadTerms">Lataa ja tallenna pdf <i class="text-red icon-download-alt"></i></a>
        </div>
      </div>

      <div class="mt-2 pb-2">
        Voit perua tilauksen veloituksetta, mikäli teet sen viimeistään kolme arkipäivää ennen ajoneuvon sovittua luovutusaikaa.
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderSummary',

  computed: {
    ...mapState({
      order: state => state.order.order,
      order_car: state => state.order.order.order_product_car
    })
  },

  methods: {
    downloadTerms () {
      this.$store.dispatch('order/downloadTerms', {
        url: this.order.contract_terms,
        order_number: this.order.order_number
      })
        .catch(() => {
          this.$swal({
            title: 'Virhe sopimusehtojen latauksessa',
            icon: 'error',
            text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
          });
        });
    }
  }
}
</script>
