<template>
  <b-modal
    ref="editCustomerInfo"
    id="editCustomerInfo"
    size="md"
    title="Muokkaa yhteystietojasi"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :footer-class="'justify-content-center'"
    >

    <div class="row">
      <div class="col-12 col-md-6">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('first_name') }">
          <label for="first_name">Etunimi</label>
          <input type="text" v-model="form.first_name" class="form-control" id="first_name">
          <small class="text-muted validation-error--message">{{ form.errors.get('first_name') }}</small>
        </fieldset>
      </div>

      <div class="col-12 col-md-6">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('last_name') }">
          <label for="last_name">Sukunimi</label>
          <input type="text" v-model="form.last_name" class="form-control" id="last_name">
          <small class="text-muted validation-error--message">{{ form.errors.get('last_name') }}</small>
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('street_address') }">
          <label for="street_address">Katuosoite</label>
          <input type="text" v-model="form.street_address" class="form-control" id="street_address">
          <small class="text-muted validation-error--message">{{ form.errors.get('street_address') }}</small>
        </fieldset>
      </div>

      <div class="col-12 col-md-6">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('zip_code') }">
          <label for="zip_code">Postinumero</label>
          <input type="text" v-model="form.zip_code" class="form-control" id="zip_code">
          <small class="text-muted validation-error--message">{{ form.errors.get('zip_code') }}</small>
        </fieldset>
      </div>

      <div class="col-12 col-md-6">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('post_office') }">
          <label for="post_office">Kaupunki</label>
          <input type="text" v-model="form.post_office" class="form-control" id="post_office">
          <small class="text-muted validation-error--message">{{ form.errors.get('post_office') }}</small>
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('email') }">
          <label for="email">Sähköpostiosoite</label>
          {{ form.email }}
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('phone') }">
          <label for="phone">Puhelinnumero</label>
          <input type="text" v-model="form.phone" class="form-control" id="phone">
          <small class="text-muted validation-error--message">{{ form.errors.get('phone') }}</small>
        </fieldset>
      </div>
    </div>

     <template v-slot:modal-footer="{ ok, cancel}">

        <button class="btn btn-t1-primary" @click="saveForm" :class="{'disabled': form.busy}">
          <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
          Tallenna
        </button>
        <button class="btn btn-t1-default" @click="cancel()">
          Peruuta
        </button>

      </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'ModalEditCustomerInfo',

  data () {
    return {
      error: null,

      form: new Form({
        first_name: '',
        last_name: '',
        street_address: '',
        zip_code: '',
        post_office: '',
        email: '',
        phone: ''
      })
    }
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    })

  },

  methods: {
    saveForm () {
      this.$store.dispatch('customer/updateCustomerInfo', this.form).then(() => {
        this.$store.dispatch('customer/getCustomer').then(() => {
          this.$swal({
            title: 'Yhteystiedot päivitetty',
            icon: 'success'
          });
          this.cancel();
        }).catch(() => {
          // Do nothing
        });
      })
    },

    cancel () {
      this.$bvModal.hide('editCustomerInfo');
    }
  },

  created () {
    this.form.fill(this.customer);
  }
}
</script>
