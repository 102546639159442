<template>
  <section id="footer" class="py-2 py-md-3 bg-white">
    <div class="container">

      <div class="row py-2">

        <div class="col-12 col-md-3 order-2 order-md-1 mt-2 mt-md-0">
          <h3>Tietoa meistä</h3>

          <ul class="mt-2 list-unstyled">
            <!-- <li><a :href="contactUrl" target="_blank">Ota yhteyttä</a></li> -->
            <li>Ota yhteyttä chat-palvelumme kautta.<br><br/>
              Chat on avoinna<br/>
              ma - pe klo 08-22<br/>
              la - su klo 10-18<br/>
              Suljettu juhlapyhinä
            </li>
            <li class="mt-2"><a :href="promoSite + 'tietosuoja'" target="_blank">Tietosuoja</a></li>
            <li><a :href="promoSite + 'kayttoehdot'" target="_blank">Käyttöehdot</a></li>
            <li><a :href="promoSite + 'evasteet'" target="_blank">Evästeet</a></li>
            <li><a :href="getTermsUrl" target="_blank">Sopimusehdot (pdf)</a></li>

          </ul>
        </div>

        <div class="col-12 col-md-9 order-1 order-md-2">
          <h3 >Usein kysytyt kysymykset</h3>
          <div v-for="(section, idx) in sections" :key="idx" class="py-1">
            <h4 class="mt-1 pb-2 border-bottom">{{ section.title }}</h4>
            <div v-for="(accordion, idx2) in section.questions" :key="idx2" class="border-bottom py-1 px-1">
              <a class="d-flex cursor-pointer link" @click="showIndex(idx + '-' + idx2)">
                <i class="icon-chevron-down text-red mr-1" style="padding-top: 5px;"></i>
                <span>{{ accordion.header }}</span>
              </a>

              <div v-if="showContent(idx + '-' + idx2)" class="ml-2">
                <div class="py-1">{{ accordion.content }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Footer',

  computed: {
    contactUrl () {
      return process.env.VUE_APP_CONTACT_PAGE;
    },

    promoSite () {
      return process.env.VUE_APP_PROMO_SITE;
    },

    getTermsUrl () {
      return this.$store.state.contract_terms;
    }
  },

  data: () => ({
    acc: [],
    sections: [
      {
        title: 'Mikä Toyota Kuukausitilaus on?',
        questions: [
          {
            header: 'Mitä kuukausitilaus tarkoittaa?',
            content: 'Kuukausitilaus tarkoittaa, että voit nauttia autoilun vapaudesta ja eduista yksi kuukausi kerrallaan ilman, että sitoudut auton omistamiseen. Kuukausitilaajana tilaat palvelun aina yksi kuukausi kerrallaan, kuten esimerkiksi suoratoistopalvelut. Tilaus uusiutuu automaattisesti joka kuukausi, kunnes päätät itse luopua tilauksesta.'
          },
          {
            header: 'Mitä palvelun hintaan kuuluu?',
            content: 'Palvelun hintaan kuuluu auton käyttöoikeus sekä 1500 km/kk ajomäärä. Me vastaamme auton huolloista, vakuutuksista, ajoneuvoverosta sekä renkaiden vaihdosta, säilytyksestä ja tarpeen vaatiessa uusimisesta. Auton luovutuksen yhteydessä saat halutessasi opastusta auton käyttöön ja ominaisuuksiin liittyen. Lisäksi saat palvelun käytön ajaksi suoran puhelinnumeron, josta tavoitat asiakaspalvelumme.'
          },
          {
            header: 'Mitä palvelun hintaan ei kuulu?',
            content: 'Palvelun hintaan ei kuulu polttoaine, tuulilasinpesunesteen lisääminen, ajoneuvon peseminen käytön aikana, ajoneuvon toimitus, pysäköintimaksut tai mahdolliset rikemaksut.'
          },
          {
            header: 'Miten ja kuinka usein palvelu maksetaan?',
            content: 'Tarvitset palvelun tilaamiseen ja maksamiseen voimassa olevan maksukortin. Veloitus maksukortilta lähtee aikaisintaan kahdeksan vuorokautta ennen sovittuaajoneuvon luovutuspäivää tai uuden tilausjakson alkamista. Maksu veloitetaan kuukausittain maksukortiltasi automaattisesti ja voit myös vaihtaa maksukorttiasi Oma Kuukausitilaus –sivusi kautta.'
          },
          {
            header: 'Ovatko palvelussa olevat autot uusia?',
            content: 'Autot ovat uudehkoja ja vähänajettuja, maksimissaan 2 vuotta vanhoja. Joskus kohdalle voi osua uusikin auto, mutta sitä emme pysty lupaamaan. Saatavilla olevat autot vaihtuvat jatkuvasti.'
          },
          {
            header: 'Onko Toyota Kuukausitilaus sama kuin leasing?',
            content: 'Ei, sillä leasing-sopimus sovitaan aina etukäteen määräajaksi ja yleensä useiksi vuosiksi. Toyota Kuukausitilaus -palvelussa voit ajaa autolla aina kuukauden kerrallaan eikä sinun tarvitse tilaushetkellä tietää, kuinka pitkään tarvitset autoa käyttöösi.'
          },
          {
            header: 'Kuinka paljon Toyota Kuukausitilaus maksaa?',
            content: 'Hinnat ovat autokohtaisia ja näet ne sivujamme ylöspäin selaamalla.'
          }
        ]
      },
      {
        title: 'Palvelun tilaaminen ja auton käyttö',
        questions: [
          {
            header: 'Jos tilaan palvelun, mutta tulen katumapäälle, voinko perua tilauksen ennen auton noutoa?',
            content: 'Kyllä, voit perua tilauksen veloituksetta, mikäli teet sen viimeistään kolme arkipäivää ennen ajoneuvon sovittua luovutusaikaa. Esimerkiksi jos luovutus on sovittu perjantaiksi klo 8:00, voit peruuttaa palvelun tilauksen veloituksetta tiistaihin klo 8:00 asti.'
          },
          {
            header: 'Kuinka paljon saan ajaa? Mitä tapahtuu, jos ylitän kilometrimäärän?',
            content: 'Tilaukseen sisältyvä ajokilometrimäärä on 1500 km/kk. Mikäli ajat enemmän, voit halutessasi ostaa Oma Toyota Kuukausitilaus -sivun kautta lisäkilometripaketteja auton tilattuasi. Ilman lisäkilometripakettia ylittävistä ajokilometreistä peritään auton palautuksen jälkeen 0,27 €/km (sis. ALV).'
          },
          {
            header: 'Voinko aloittaa missä kohtaa kuukaudessa tahansa?',
            content: 'Voit saada auton käyttöösi arkipäivisin, maanantaista perjantaihin. Muutoin voit itse päättää, minä päivänä tilauksesi aloitat. Auton voit varata aikaisintaan kahden arkipäivän päähän.'
          },
          {
            header: 'Voinko kuljettaa lemmikkejäni autossa?',
            content: 'Pääsääntöisesti autoissamme ei saa kuljettaa lemmikkieläimiä. Jos sinulla kuitenkin on siihen tarve, niin ole yhteydessä asiakaspalveluumme. Tarkistamme mahdollisuuden saada auto, jossa lemmikkisi kulkee mukana.'
          },
          {
            header: 'Voiko minulle tulla muita kuluja kuukausimaksun lisäksi?',
            content: 'Kuukausimaksun lisäksi maksat itse polttoaineen ja pysäköintimaksut sekä mahdolliset rikemaksut. Tilaukseen sisältyvä ajokilometrimäärä on 1500 km/kk. Jos haluat ajaa enemmän, ylimääräisistä kilometreistä peritään auton palautuksen jälkeen 0,27 €/km (sis. ALV). Oma Kuukausitilaus -sivun kautta voit myös tarvittaessa ostaa lisäkilometripaketteja auton tilauksen jälkeen.'
          },

          {
            header: 'Mitä teen, jos auton lasiin tulee kiveniskemä?',
            content: 'Tuulilasi kannattaa korjauttaa aina mahdollisimman pian. Näin kiveniskemä ei pääse laajenemaan ja riko koko lasia. Ota mahdollisimman nopeasti yhteyttä Toyota –liikkeeseen, josta hait autosi. Jälki kannattaa suojata teipinpalalla tai tarralla. Sen tarkoitus on suojella vauriokohtaa lialta ja kosteudelta, ja näin helpottaa sen korjaamista ja ehkäistä sen leviämistä. Auton tuulilasin korjaamisesta veloitetaan sinulta lasin korjaamisesta aiheutuvat kulut. Mikäli tuulilasi joudutaan vaihtamaan, veloitetaan siitä tuulilasin hinta, maksimissaan 500 € omavastuu.'
          },

          {
            header: 'Missä voin huollattaa tai vaihdattaa renkaat Toyota Kuukausitilaus –autooni?',
            content: 'Renkaiden vaihto ja huollot tulee tehdä siinä liikkeessä, josta auto on noudettu tai toimitettu sinulle kotiin. Emme voi valitettavasti toimittaa renkaita toiselle paikkakunnalle. Huomaathan, että tästä tulevat kilometrit lasketaan normaalisti mukaan palveluun sisältyviin ajokilometreihisi.'
          },

          {
            header: 'Voisinko millään ajaa enemmän kuin 1500 km/kk?',
            content: 'Mikäli mielesi tekee roadtripille, voit ostaa Oma Kuukausitilaus -sivun kautta lisäkilometripaketteja auton tilauksen jälkeen. Lisäkilometripakettien avulla saat helposti räätälöityä sopivan ajomäärän ja niiden ostaminen on edullisempaa kuin ylikilometrien maksaminen tilauksen päätyttyä.'
          },
          {
            header: 'Mitä jos saamani auto ei miellytäkään minua? Voinko vaihtaa sen?',
            content: 'Mikäli saamasi auto ei jonkun ominaisuuden puolesta miellytä sinua, voit olla yhteydessä asiakaspalveluumme ja kertoa toiveistasi. Selvitämme, olisiko auto mahdollista vaihtaa toiseen. Tätä emme voi luvata, mutta voimme yrittää. On hyvä muistaa, että palvelussa sitoudut aina kuukaudeksi kerrallaan, joten jos sinusta alkaa tuntumaan siltä, että auto ei ole sellainen kuin toivoisit emmekä saa sitä vaihdettua toiseen, voit palauttaa auton ja esimerkiksi kokeilla jotain muuta automalleistamme.'
          },
          {
            header: 'Mihin saan pysäköidä auton ja maksanko siitä itse?',
            content: 'Pysäköithän auton aina tieliikennelain mukaisesti käyttäen joko parkkikiekkoa tai maksaen pysäköinnit itse. Palvelun hintaan ei kuulu pysäköinti.'
          },
          {
            header: 'Onko tilaamani auton rekisterinumero mahdollista saada tietää etukäteen? Tarvitsen sitä asukaspysäköintitunnusta varten.',
            content: 'Saat tietää tilaamasi auton rekisterinumeron kun noudat auton tai se toimitetaan sinulle kotiinkuljetuksella. Tarvittaessa voit käyttää saamaasi Toyota Kuukausitilauksen ajolupaa anoessasi asukaspysäköintilupaa. Lisätietoja asukaspysäköintitunnuksen hankkimisesta saat kotikuntasi verkkosivuilta.'
          },
          {
            header: 'Mistä kaikkialta voin saada autoja?',
            content: 'Autoja on tarjolla kuukausitilauskäyttöön Toyota-jälleenmyyjillä ympäri Suomea. Näet autojen saatavuuden läheltäsi valittuasi automallin, haluamasi noutopäivänsekä kerrottuasi haluamasi noutopaikan.'
          },
          {
            header: 'Voinko varata auton etukäteen usean kuukauden päähän esimerkiksi lomaani varten?',
            content: 'Toyota Kuukausitilauksen toimintaperiaate on kuin suoratoistopalveluissa - tilaa auto tarpeeseesi silloin kun tarvitset sitä. Haluamme mahdollistaa ihmisten liikkuvuutta ja välttää tilannetta, jossa autot seisovat varattuina ilman käyttöä. Mikäli mahdollistamme autojen varaamisen usean kuukauden päähän, emme pysty antamaan kyseistä autoa toiselle asiakkaalle, koska tilaus on voimassa toistaiseksi. Pyrimme kuitenkin lisäämään autojen määrää palveluun eli on todennäköistä, että saat auton käyttöön toivomanasi ajankohtana, vaikka sen varaaminen jäisikin myöhemmäksi.'
          },
          {
            header: 'Miksi tilauskalenteri on avoinna vain kolmen viikon päähän?',
            content: 'Toyota Kuukausitilaus toimii kuten suoratoistopalvelut. Ota se käyttöön, sillä hetkellä kun tarvitset sitä. Tämän vuoksi emme pysty varaamaan sinulle autoa puolen vuoden päähän, koska autoa ei pystytä odotusaikana luovuttamaan toiselle asiakkaalle. Pyrimme kuitenkin lisäämään autoja palveluun kysynnän mukaan eli todennäköisesti saat auton käyttöön toivomanasi ajankohtana.'
          },
          {
            header: 'Onko autoissa peräkoukku ja suksiboksi?',
            content: 'Ei ole.'
          },
          {
            header: 'Minkälaisia ovat tarjoamanne lisäkilometripaketit ja kuinka paljon ne maksavat?',
            content: 'Tarjoamme kolmea eri lisäkilometripakettia ja voit ostaa niitä Oma Kuukausitilaus -sivulta tilauksen jälkeen missä vaiheessa vain. Paketit ovat 250 km (62,5 €), 500 km (125 €) sekä 1000 km (250 €).'
          },

          {
            header: 'Tien päällä voi tulla yllättäviä tilanteita, mitä teen silloin?',
            content: 'Autoon liittyvissä mahdollisissa pienissä ongelmatilanteissa, jotka eivät estä ajamista, olethan ensisijaisesti yhteydessä auton sinulle luovuttaneeseen Toyota-jälleenmyyjäliikkeeseen. Kiireellisissä asioissa tai ajamisen estävissä tilanteissa, mikäli Toyota-jälleenmyyjäliike ei ole auki, voit olla yhteydessä 24/7 maksuttomaan tiepalvelun numeroon: 0800 13663.'
          }
        ]
      },
      {
        title: 'Auton nouto ja palautus',
        questions: [
          {
            header: 'Mitä minun tulee huomioida autoa noutaessa?',
            content: 'Auton voit noutaa palvelun aloittamispäivänä klo 8:00 alkaen. Voit valita sinulle sopivan noutoajankohdan vastaamalla tekstiviestiin, jonka lähetämme päivää ennen noutoajankohtaa. Ota mukaasi ajokortti, joka tarkistetaan auton luovuttamisen yhteydessä. Auto luovutetaan aina tankki täynnä. Saat tarvittaessa myös opastusta auton käyttöön noudon yhteydessä.'
          },
          {
            header: 'Miten päätän tilauksen?',
            content: 'Kun haluat lopettaa tilauksen, voit tehdä sen Oma Kuukausitilaus –sivusi kautta. Ilmoita palvelun lopettamisesta viimeistään 14 vuorokautta ennen seuraavan tilauskauden alkamista. Esimerkiksi, jos otat auton käyttöön 15.4 ja haluat palauttaa auton 15.5, niin päätä tilaus viimeistään 1.5.'
          },
          {
            header: 'Mitä minun tulee huomioida auton palautuksessa?',
            content: 'Palauta auto siistissä kunnossa ja tankki täynnä samaan toimipisteeseen mistä noudit auton. Palautus on tehtävä liikkeen aukioloaikana. Autoliike tarkistaa auton ja mahdolliset vauriot.'
          },
          {
            header: 'Mitä jos sopimuksen mukaisena palautuspäivänä toimipiste ei ole auki?',
            content: 'Mikäli palautuspäivänä toimipiste ei ole auki, niin auto tulee palauttaa seuraavana arkipäivänä kello 09.00 mennessä.'
          },
          {
            header: 'Onko minun mahdollista tilata auto kuljetettuna kotiin?',
            content: 'Voit tilata kotiinkuljetuksen tietyistä jälleenmyyjäliikkeistä. Näet mahdollisuuden kotiinkuljetukseen autoa valitessasi. Kotiinkuljetuksen hinta vaihtelee 69 – 165 € välillä riippuen toimitusmatkasta. Kotiinkuljetuksen maksimietäisyys on 120 kilometriä.'
          }
        ]
      },
      {
        title: 'Sopimusasiat ja vakuutus',
        questions: [
          {
            header: 'Voiko autolla ajaa muut kuin palvelun tilannut henkilö?',
            content: 'Kyllä voi, mutta muut kuljettajat tulee lisätä auton luovutushetkellä kirjoitettavaan ajolupaan. Mahdollisten muiden kuljettajien ei tarvitse kuitenkaanolla mukana noutohetkellä. Palvelun tilaaja on vastuussa autosta myös silloin, mikäli hän luovuttaa auton toiselle kuljettajalle. Lisäksi palvelun tilaaja on vastuussa toisen kuljettajan ajo-oikeuden ja kunnon tarkistamisesta.'
          },
          {
            header: 'Mikä on omavastuu vahinkotapauksissa?',
            content: 'Omavastuu kolari-, lasi-, palo-, ilkivalta-, varkaus- tai hirvieläinvahingoissa on 500 €. Tarkemmat ehdot käydään läpi sopimusehdoissa.'
          },
          {
            header: 'Voinko pienentää omavastuuosuutta?',
            content: 'Toistaiseksi omavastuuosuuden tiputtaminen ei ole mahdollista. Omavastuu on 500 €.'
          },
          {
            header: 'Saanko lähteä autolla ulkomaille?',
            content: 'Kuukausitilausautolla saa ajaa ainoastaan Suomen rajojen sisäpuolella. '
          },
          {
            header: 'Merkataanko minut auton omistajaksi? ',
            content: 'Ei. Sinä et missään vaiheessa omista autoa, vaan saat palvelun kautta oikeuden käyttää sitä kuin omaa autoasi kiinteällä ennalta määritetyllä hinnalla.'
          },
          {
            header: 'Kuinka kauan saan pitää autoa ja olla Toyota Kuukausitilaaja?',
            content: 'Tilauksesi kestolle ei ole haluttu määritellä ylärajaa, mutta sopimusehtojen nojalla autosi saatetaan vaihtaa tilauksesi aikana, mikäli innostut pitämään autoa pitkäänkin.'
          }
        ]
      }
    ]
  }),

  methods: {
    showIndex (idx) {
      this.acc.indexOf(idx) === -1 ? this.acc.push(idx) : this.acc.splice(this.acc.indexOf(idx), 1);
    },

    showContent (idx) {
      return this.acc.indexOf(idx) !== -1;
    }
  }
}
</script>
