<template>
  <div class="page">
    <div class="login bg-white py-3 px-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3 class="bold mb-2">Salasana unohtunut?</h3>
            <p>Voit tilata salasanan vaihtolinkin sähköpostiisi alla olevalla lomakkeella. Syötä lomakkeeseen sähköpostiosoite ja paina tilaa.</p>
          </div>
        </div>
        <div v-if="form.errors.has('error')" class="bg-message p-1 mt-2 error normal">{{ form.errors.get('error') }}</div>

        <div v-on:keyup.enter="login">
          <div class="row mt-2">
            <div class="col-12">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('email') }">
                <label for="email">Sähköpostiosoite</label>
                <input v-model="form.email" type="email" class="form-control" id="email">
                <small class="text-muted validation-error--message">{{ form.errors.get('email') }}</small>
              </fieldset>
            </div>

          </div>

          <div class="row mt-2">
            <div class="col-12 col-sm-6 mb-1">
              <button @click="submit" class="btn btn-t1-primary" :class="{'disabled': form.busy}"><i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>Tilaa</button>
            </div>
            <div class="col-12 col-sm-6 text-right mt-1">
              <router-link :to="{name: 'Login'}">Kirjaudu sisään</router-link>
            </div>
          </div>

          <div class="row border-top mt-2 pt-2">
            <div class="col-12">
              Uusi asiakas? Tee tilaus <router-link to="/">tästä!</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vform'

export default {
  name: 'ForgotPassword',

  data () {
    return {
      form: new Form({
        email: ''
      })
    }
  },

  methods: {
    submit () {
      this.$store.dispatch('auth/forgotPassword', this.form).then(() => {
        this.form.reset();
        this.$swal({ text: 'Salasanan vaihtolinkki on lähetetty sähköpostiosoitteeseesi.' });
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$swal({
            title: 'Virhe lähetyksessä',
            text: 'Tarkasta sähköpostiosoite ja yritä uudelleen.',
            icon: 'error'
          });
        }
      });
    }
  },

  created () {
    this.$store.dispatch('auth/fetchMe').then(() => {
      // Logged in
      this.$router.push({ name: 'NewOrder' });
    }).catch(() => {});
  }
}
</script>
