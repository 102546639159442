<template>
  <b-modal
    ref="availableCities"
    id="availableCities"
    size="md"
    title=""
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :footer-class="'border-top justify-content-center'"
    >

    <h4>Toyota Kuukausitilaus -palvelun noutopiste löytyy seuraavista kaupungeista</h4>
    <p class="mt-2">Kaikkia automalleja ei välttämättä ole vapaina kaikilla paikkakunnilla.</p>

    <div class="row my-2">
      <div class="col-12 col-sm-6">
        <ul class="list-unstyled mb-0">
          <li v-for="(city, idx) in getFirstHalf" :key="idx">
            {{ city }}
          </li>
        </ul>
      </div>
      <div class="col-12 col-sm-6">
        <ul class="list-unstyled mb-0">
          <li v-for="(city, idx) in getSecondHalf" :key="idx">
            {{ city }}
          </li>
        </ul>
      </div>
    </div>


    <template v-slot:modal-footer="{ ok, cancel }">
      <button class="btn btn-t1-primary" @click="cancel">
        Sulje
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ModalAvailableCities',

  computed: {
    ...mapState({
      cities: state => state.available_cities
    }),

    getFirstHalf () {
      return this.cities.slice(0, Math.ceil(this.cities.length / 2));
    },

    getSecondHalf () {
      return this.cities.slice(Math.ceil(this.cities.length / 2));
    }
  },

  methods: {
    cancel () {
      this.$bvModal.hide('availableCities');
    }
  }
}
</script>"
