<template>
  <section class="pb-2 pb-sm-3 px-2 overflow-hidden">
    <div class="order-steps">
      <div class="progress" :class="'step-' + step"></div>
      <div class="step" :class="getClass(1)">
        <span v-if="step === 1" class="bold">1</span>
        <span v-else><i class="icon-ok"></i></span>
        <p>Saatavuus</p>
      </div>

      <div class="step" :class="getClass(2)">
        <span v-if="step < 3" class="bold">2</span>
        <span v-else><i class="icon-ok"></i></span>
        <p>Tilauksesi</p>
      </div>

      <div class="step" :class="getClass(3)">
        <span v-if="step < 4" class="bold">3</span>
        <span v-else><i class="icon-ok"></i></span>
        <p>Tunnistautuminen</p>
      </div>

      <div class="step" :class="getClass(4)">
        <span class="bold">4</span>
        <p>Maksutiedot</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderSteps',

  props: {
    step: {
      default: 1,
      required: false
    }
  },

  computed: {
    ...mapState({
      selectedModel: state => state.models.selected
    })
  },

  methods: {
    getClass (step) {
      if (this.step === step) return 'active';
      else if (this.step > step) return 'success';
    }
  }
}
</script>
