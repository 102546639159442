<template>
  <div>
    <OrderSteps :step="3"/>

    <template v-if="!order">
      <div class="text-center py-3">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>

    <template v-else-if="identified">
      <IdentificationProgress />
    </template>

    <template v-else-if="identifying">
      <EIdentIframe :url="iframe_url" />
    </template>

    <template v-else>
      <div class="py-2 text-center">
        <p class="mb-2">Seuraavaksi tarkistamme henkilöllisyytesi.</p>

        <img src="~@/assets/tunnistautuminen.svg" />
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-7 text-center">

          <p class="mb-2">Tarvitset tunnistautumiseen pankkitunnukset tai mobiilivarmenteen.</p>
          <button @click="loadIdentification" class="btn btn-lg btn-t1-primary" :class="{'disabled': loading}">
            <i v-if="loading" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
            Jatka tunnistautumaan
          </button>

          <p class="mt-3">Kun tunnistaudut, Toyota saa tietoonsa henkilötunnuksesi, jonka avulla Toyota tarkistaa luottotietosi ja ajo-oikeutesi voimassaolon. Henkilötunnuksen käsittely perustuu tietosuojalain 29 §:ään.</p>
        </div>
      </div>

    </template>

    <OrderCart v-if="order" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderSteps from '@/components/order/newOrder/OrderSteps'
import EIdentIframe from '@/components/order/newOrder/EIdentIframe'
import IdentificationProgress from '@/components/order/newOrder/IdentificationProgress'
import OrderCart from '@/components/order/newOrder/OrderCart'

export default {
  name: 'Identify',

  components: {
    OrderSteps,
    EIdentIframe,
    IdentificationProgress,
    OrderCart
  },

  data () {
    return {
      identified: false,
      identifying: false,
      loading: false,
      iframe_url: null
    }
  },

  computed: {
    ...mapState({
      orderLoading: state => state.order.loading,
      order: state => state.order.order,
      customer: state => state.customer.customer
    })
  },

  async mounted () {
    await this.$store.dispatch('order/getOrder');
    // var redirect = await this.$store.dispatch('order/redirectNewOrder');
    // if (redirect) return;

    if (this.customer.identified) {
      this.$store.dispatch('models/setModelDataLayer', this.order);
      // If identified, show next progress
      this.identified = this.customer.identified;
    } else if (typeof this.$route.query.virhe !== 'undefined') {
      this.$swal({
        title: 'Virhe tunnistautumisessa',
        text: 'Tunnistautumisessa tapahtui virhe tai se peruutettiin. Ole hyvä ja tunnistaudu uudelleen.'
      });
    }
  },

  methods: {
    loadIdentification () {
      this.loading = true;
      this.$store.dispatch('order/createIdentification').then(response => {
        if (process.env.VUE_APP_EMBEDDED_EIDENT === 'true') {
          // Use iframe embedded identification
          this.iframe_url = response.iframe_url;
          this.identifying = true;
        } else {
          // Use standalone identification
          window.location.href = response.iframe_url;
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
