<template>
<div class="bg-grey-light mb-2">

  <!-- Order expired/pending -->
  <div class="bg-grey-transparent" v-if="!isConfirmed && !isRemoved && !isWithdrawn">
    <div class="row py-2 px-2">
      <div class="col-12 ">
        <template v-if="order.status === 'expired'">
          <strong class="mr-1">Tilauksesi autovaraus on vanhentunut!</strong>
        </template>

        <template v-if="order.status === 'pending'">
          <strong class="mr-1">Tilauksesi on kesken!</strong>
        </template>
        <div class="float-md-right mt-1 mt-md-0">
          <router-link class="btn btn-sm mt-1 mt-md-0 btn-t1-primary" :to="{name: 'NewOrder'}">Jatka tilausta</router-link>
          <button class="btn btn-sm mt-1 mt-md-0" @click="removeOrder">Poista tilaus</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Order removed -->
  <div class="bg-grey-transparent" v-if="isRemoved">
    <div class="row py-2 px-2">
      <div class="col-12 ">
        <strong class="mr-1">Tilauksesi autovaraus on vanhentunut!</strong>
      </div>
    </div>
  </div>

  <div class="bg-grey-transparent" v-else-if="isWithdrawn">
    <div class="row py-2 px-2">
      <div class="col-12 ">
        <strong class="mr-1">Tilauksesi on peruutettu!</strong>
      </div>
    </div>
  </div>

  <!-- Order errors -->
  <div class="bg-error" v-if="getErrors">
    <div class="row py-2 px-2">
      <div class="col-12 ">
        <template v-if="order.order_errors.invoices">
          <strong>Virheitä veloituksissa</strong>
          <p class="mt-1 mb-0">Pyytäisimme sinua tarkistamaan, että ilmoittamasi maksutiedot ovat oikein, kortilla on riittävästi katetta ja korttisi on avoin verkko-ostoille.</p>
        </template>
      </div>
    </div>
  </div>

  <OrderItemPickupTime :order="order" />
  <OrderItemCancelling :order="order" />

  <div class="row py-2 px-2 ">
    <div class="col-12 col-md-7 col-lg-7">
      <h4>Toyota Kuukausitilaus</h4>
      <h3>{{ car.product_car.display_name }} {{ car.price | value(0) }}{{'\xa0'}}€/kk</h3>

      <div class="row my-2">
        <div :class="getListClassLeft">Tilauksen aloituspäivä:</div>
        <div :class="getListClassRight">{{ order.start_date | moment('D.M.Y') }}</div>

        <template v-if="order.pickup_time">
          <div :class="getListClassLeft">Noutoajankohta:</div>
          <div :class="getListClassRight">{{ order.pickup_time  }}</div>
        </template>

        <template v-if="order.cancel_date">
          <div :class="getListClassLeft">Tilauksen päättymispäivä:</div>
          <div :class="getListClassRight">{{ order.cancel_date | moment('D.M.Y') }}</div>
        </template>

        <template v-if="isConfirmed && order.next_payment_date">
          <div :class="getListClassLeft">Seuraava veloituspäivä:</div>
          <div :class="getListClassRight">{{ order.next_payment_date | moment('D.M.Y') }}</div>
        </template>



        <div :class="getListClassLeft">Noutopiste:</div>
        <div :class="getListClassRight">{{ order.dealer.display_name }}</div>

        <template v-if="order.order_extra_services.length">
          <div :class="getListClassLeft">Lisäpalvelut:</div>
          <div :class="getListClassRight">
            <div v-for="(es, idx) in getServices" :key="idx">
              {{ es.service_name }} {{ es.price | value(2) }}{{'\xa0'}}€
            </div>
          </div>
        </template>
      </div>

      <a class="link cursor-pointer" @click="downloadTerms">Sopimusehdot <i class="icon-angle-right text-red"></i></a>

    </div>

    <div class="col-12 col-md-5 col-lg-5 text-md-right mt-2 mt-md-0">
      <div><img :src="require('@/assets/car/' + car.product_car.model.toLowerCase() + '.png')" /></div>

      <button v-if="showPurchaseExtraServices" class="btn btn-t1-primary mt-2" @click="showPurchaseModal">Osta lisäkilometripaketti</button>
      <button v-if="showCancellation" class="btn btn-t1-function mt-2" @click="showCancelModal">Tilauksen päättäminen</button>
    </div>
  </div>

  <OrderItemExtraServices :extraServices="order.order_extra_services" :orderNumber="order.order_number" v-if="isConfirmed" />
  <OrderItemInvoices :invoices="order.invoices" v-if="isConfirmed" />
  <OrderItemPaymentCards :order="order" v-if="isConfirmed" />
  <ModalCancelOrder :order="order" />
  <ModalPurchaseExtraServices :order="order" />

</div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import OrderItemExtraServices from './OrderItemExtraServices';
import OrderItemInvoices from './OrderItemInvoices';
import OrderItemPaymentCards from './OrderItemPaymentCards';
import OrderItemPickupTime from './OrderItemPickupTime';
import ModalPurchaseExtraServices from './ModalPurchaseExtraServices';
import ModalCancelOrder from './ModalCancelOrder';
import OrderItemCancelling from './OrderItemCancelling';

export default {
  name: 'OrderItem',
  props: ['order'],

  components: {
    OrderItemExtraServices,
    OrderItemInvoices,
    OrderItemPaymentCards,
    OrderItemPickupTime,
    OrderItemCancelling,
    ModalCancelOrder,
    ModalPurchaseExtraServices
  },

  computed: {
    ...mapState({
    }),

    car () {
      return this.order.order_product_car;
    },

    getListClassLeft () {
      return 'col-12 col-sm-5 col-md-6 col-lg-5';
    },

    getListClassRight () {
      return 'col-12 col-sm-7 col-md-6 col-lg-7 mb-1 mb-sm-0';
    },

    isConfirmed () {
      return this.order.status === 'confirmed' || this.order.status === 'delivered' || this.order.status === 'signed' || this.order.status === 'cancelled' || this.order.status === 'under_cancellation';
    },

    isRemoved () {
      return this.order.status === 'removed';
    },

    isWithdrawn () {
      return this.order.status === 'withdrawn';
    },

    getErrors () {
      return this.order.order_errors && this.order.order_errors.invoices;
    },

    showCancellation () {
      return this.order.status === 'delivered';
    },

    showPurchaseExtraServices () {
      return this.order.available_extra_services.length && this.isConfirmed;
    },

    getServices () {
      return _.filter(this.order.order_extra_services, item => item.sale_phase === 'pre_sale');
    }
  },

  methods: {
    showCancelModal () {
      this.$bvModal.show('cancelOrder-' + this.order.order_number);
    },

    showPurchaseModal () {
      this.$bvModal.show('purchaseExtraServices-' + this.order.order_number);
    },

    downloadTerms () {
      this.$store.dispatch('order/downloadTerms', {
        url: this.order.contract_terms,
        order_number: this.order.order_number
      })
        .catch(() => {
          this.$swal({
            title: 'Virhe sopimusehtojen latauksessa',
            icon: 'error',
            text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
          });
        });
    },

    removeOrder () {
      this.$swal({
        title: 'Tilauksen poisto',
        text: 'Haluatko varmasti poistaa keskeneräisen tilauksen?',
        showCancelButton: true,
        cancelButtonText: 'Peruuta',
        confirmButtonText: 'Kyllä'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('customer/deleteOrderInProgress', this.order.order_number).then(() => {
            this.$store.dispatch('customer/getOrders');
          }).catch(() => {
            this.$swal({
              title: 'Virhe tilauksen poistossa',
              icon: 'error',
              text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
            });
          });
        }
      });
    }
  }
}
</script>
