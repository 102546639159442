<template>
  <div class="d-inline-block">
    <button @click="selectService()" class="btn bg-white shadow-sm mb-1" >
      <i class="fa" style="width: 25px" :class="{'fa-square-o': !isSelected, 'text-red fa-check': isSelected }"></i>
      {{ item.service_name }} <strong>{{ item.display_price | value(0) }}{{'\xa0'}}€</strong>
    </button>

    <b-modal
      :ref="modalId"
      :id="modalId"
      size="md"
      title=""
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :header-class="'no-title'"
      :footer-class="'border-top justify-content-center'"
      >

      <div>
        <template v-if="form && item.service_key === 'home_delivery'">
          <h3>Syötä osoite kotiinkuljetusta varten</h3>
          <p class="my-2">Kun olet vahvistanut tilauksesi, asiakaspalvelumme on sinuun yhteydessä puhelimitse kahden arkipäivän sisällä.</p>
          <div class="row">
            <div class="col-12">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('address')}">
                <label for="address">Osoite</label>
                <input type="text" class="form-control" id="address" v-model="form.address">
                <small class="validation-error--message">{{ form.errors.get('address') }}</small>
              </fieldset>
            </div>

            <div class="col-12 col-md-4">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('zip')}">
                <label for="zip">Postinumero</label>
                <input type="text" class="form-control" id="zip" v-model="form.zip">
                <small class="validation-error--message">{{ form.errors.get('zip') }}</small>
              </fieldset>
            </div>

            <div class="col-12 col-md-8">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('city')}">
                <label for="city">Kaupunki</label>
                <input type="text" class="form-control" id="city" v-model="form.city">
                <small class="validation-error--message">{{ form.errors.get('city') }}</small>
              </fieldset>
            </div>

            <div class="col-12 col-md-4">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('delivery_time')}">
                <label for="city">Toimitusaikatoive</label>
                <select v-model="form.delivery_time">
                  <option value="0">Valitse</option>
                  <option value="9-11">9-11</option>
                  <option value="11-13">11-13</option>
                  <option value="13-15">13-15</option>
                  <option value="15-16.30">15-16.30</option>
                </select>
                <small class="validation-error--message">{{ form.errors.get('delivery_time') }}</small>
              </fieldset>
            </div>
          </div>
        </template>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }" :class="{'disabled': form.busy}">
          <button class="btn " @click="hideModal">
            Peruuta
          </button>

          <button class="btn btn-t1-primary" @click="saveModal" :class="{'disabled': form.busy}">
            Tallenna
          </button>
        </template>
    </b-modal>
  </div>

</template>

<script>
import { Form } from 'vform'
import { mapState } from 'vuex';

export default {
  name: 'ExtraServiceItem',

  props: ['item', 'parentId'],

  data () {
    return {
      payload: {
        id: this.item.id,
        service_key: this.item.service_key,
        service_group: this.item.service_group,
        content: null
      },

      form: null
    }
  },

  computed: {
    ...mapState({
      selectedExtraServices: state => state.order.selected_extra_services
    }),

    isSelected () {
      return this.selectedExtraServices.findIndex(item => item.dealer_extra_service_id === this.item.id) > -1;
    },

    hasHomeDelivery () {
      if (!this.selectedExtraServices.length) return null;
      return (this.selectedExtraServices.find(el => el.service_key === 'home_delivery'));
    },

    modalId () {
      return 'extraServiceModal-' + this._uid;
    }
  },

  methods: {
    hideModal () {
      this.$bvModal.hide(this.modalId);
    },

    showModal () {
      this.$bvModal.show(this.modalId);
    },

    saveModal () {
      this.form.errors.clear();

      this.$store.dispatch('order/updateExtraService', this.form).then(response => {
        this.hideModal();
      }).catch(() => {});
    },

    selectService () {
      this.form = new Form({
        selected: !this.isSelected,
        service_id: this.item.id
      });

      // Open modal for home delivery address input
      if (!this.isSelected && this.item.service_key === 'home_delivery') {
        this.createDeliveryModal();
        return;
      }

      // Update extra service to order
      this.$store.dispatch('order/updateExtraService', this.form)
    },

    createDeliveryModal () {
      this.form = new Form({
        selected: true,
        service_id: this.item.id,
        address: this.hasHomeDelivery ? this.hasHomeDelivery.additional_info.address : '',
        zip: this.hasHomeDelivery ? this.hasHomeDelivery.additional_info.zip : '',
        city: this.hasHomeDelivery ? this.hasHomeDelivery.additional_info.city : '',
        delivery_time: this.hasHomeDelivery ? this.hasHomeDelivery.additional_info.delivery_time : 0
      });

      this.showModal();
    },

    editExtraService (payload) {
      if (this.isSelected && payload === 'home_delivery' && this.item.service_key === 'home_delivery') {
        this.createDeliveryModal();
      }
    }
  },

  created () {
    this.$eventBus.$on('editExtraService-' + this.parentId, this.editExtraService);
  },

  beforeDestroy () {
    this.$eventBus.$off('editExtraService-' + this.parentId, this.editExtraService);
  }
}
</script>
