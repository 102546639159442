<template>
  <div class="row justify-content-center">
    <div class="col-12 col-md-7 text-center bg-grey-light px-4 py-3">
      <h3>Haluatko saada viestin, kun toiveesi mukaisia autoja on taas vapaana?</h3>
      <button @click="showAgent" class="btn btn-white mt-2">Kyllä, jätän yhteystietoni</button>
    </div>

    <SearchAgentFormModal />
  </div>
</template>
<script>
import SearchAgentFormModal from './SearchAgentFormModal';

export default {
  name: 'SearchAgent',
  props: {},

  components: {
    SearchAgentFormModal
  },

  methods: {
    showAgent () {
      this.$bvModal.show('searchAgentForm');

      this.$gtm.trackEvent({
        event: 'emailAlert',
        category: 'Toyota Kuukausitilaus',
        action: 'Hakuvahti',
        label: 'Avaus'
      });
    }
  }
}
</script>
