<template>
  <div>
    <b-modal
      ref="updateNewOrder"
      id="updateNewOrder"
      size="xl"
      title=""
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :header-class="'no-title'"
      :footer-class="'border-top justify-content-center'"
      >

      <CheckAvailabilityForm :update="true" class="mb-2" />
      <CheckAvailabilityResult class="mb-2" v-if="availability"  :update="true" :formBusy="form.busy" />

      <template v-slot:modal-footer="{ ok, cancel }">
        <button class="btn " @click="cancel" :class="{'disabled': form.busy }">
          Peruuta
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'
import CheckAvailabilityForm from '@/components/order/availability/CheckAvailabilityForm';
import CheckAvailabilityResult from '@/components/order/availability/CheckAvailabilityResult';

export default {
  name: 'ModalUpdateNewOrder',

  components: {
    CheckAvailabilityForm,
    CheckAvailabilityResult
  },

  data () {
    return {
      error: null,

      form: new Form({
        // Form content from store
      })
    }
  },

  computed: {
    ...mapState({
      selected: state => state.models.selected,
      availability: state => state.models.availability
    })
  },

  methods: {
    cancel () {
      this.$bvModal.hide('updateNewOrder');
    },

    updateOrder () {
      this.error = null;

      this.$store.dispatch('order/updateNewOrder', this.form).then(() => {
        // Hide modal
        this.$bvModal.hide('updateNewOrder');
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.error = 'Lomakkeen lähetyksessä oli virheitä. Yritä myöhemmin uudelleen.';
            break;
          case 410:
            this.error = 'Valitsemaasi autoa ei ole enää saatavilla haluamastasi noutopisteestä.';
            break;
          case 400:
            this.error = 'Lomakkeen lähetyksessä oli virheitä. Yritä myöhemmin uudelleen.';
            break;
        }

        this.$swal({
          title: 'Virhe tilauksen päivityksessä',
          text: this.error
        });
      })
    }
  },

  created () {
    this.$eventBus.$on('selectModel', this.updateOrder);
  },

  beforeDestroy () {
    this.$eventBus.$off('selectModel', this.updateOrder);
  }
}
</script>"
