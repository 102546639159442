<template>
  <b-modal
    ref="editPassword"
    id="editPassword"
    size="md"
    title="Salasanan vaihto"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :footer-class="'justify-content-center'"
    >

    <div class="row">
      <div class="col-12">
        <p class="mb-2">Salasanan tulee sisältää vähintään 10 merkkiä, yhden ison kirjaimen ja yhden numeron.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <fieldset class="form-group  border-bottom pb-2 mb-1" :class="{'validation-error': form.errors.has('old_password') }">
          <label for="old_password">Vanha salasana</label>
          <input type="password" v-model="form.old_password" class="form-control" id="old_password">
          <small class="text-muted validation-error--message">{{ form.errors.get('old_password') }}</small>
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('password') }">
          <label for="password">Uusi salasana</label>
          <input type="password" v-model="form.password" class="form-control" id="password">
          <small class="text-muted validation-error--message">{{ form.errors.get('password') }}</small>
        </fieldset>
      </div>

      <div class="col-12">
        <fieldset class="form-group" :class="{'validation-error': form.errors.has('password_confirmation') }">
          <label for="password_confirmation">Vahvista uusi salasana</label>
          <input type="password" v-model="form.password_confirmation" class="form-control" id="password_confirmation">
          <small class="text-muted validation-error--message">{{ form.errors.get('password_confirmation') }}</small>
        </fieldset>
      </div>


    </div>



     <template v-slot:modal-footer="{ ok, cancel}">

        <button class="btn btn-t1-primary" @click="saveForm" :class="{'disabled': form.busy}">
          <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
          Tallenna
        </button>
        <button class="btn btn-t1-default" @click="cancel">
          Peruuta
        </button>

      </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'ModalEditPassword',

  data () {
    return {
      error: null,

      form: new Form({
        old_password: '',
        password: '',
        password_confirmation: ''
      })
    }
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    })

  },

  methods: {
    saveForm () {
      this.$store.dispatch('customer/updatePassword', this.form).then(() => {
        this.$swal({
          title: 'Salasana päivitetty',
          icon: 'success'
        });
        this.cancel();
      }).catch(() => {
        // Do nothing
      });
    },

    cancel () {
      this.form.reset();
      this.$bvModal.hide('editPassword');
    }
  }
}
</script>
