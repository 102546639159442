<template>
<div class="border-top py-2 px-2">
  <h4 class="cursor-pointer link" @click="show = !show">Veloitukset
    <i class="float-right text-red" :class="{'icon-chevron-down': !show, 'icon-chevron-up': show}"></i>
  </h4>

  <div v-if="show" class="mt-1 invoices">
    <template v-if="getInvoices.length">
      <div class="row mb-1">
        <div class="col-6 col-md-2 col-lg-2 support-text">Päivämäärä</div>
        <div class="col-6 col-md-2 col-lg-2 support-text">Viitenumero</div>
        <div class="col-12 col-md-6 col-lg-6 support-text">Kuvaus</div>
        <div class="col-6 col-md-1 col-lg-1 support-text text-md-right px-md-0">Summa</div>
        <div class="col-6 col-md-1 col-lg-1 text-md-right support-text">Kuitti</div>
      </div>

      <div class="row border-top invoice-row" v-for="(invoice, idx) in getInvoices" :key="idx" :class="{'status-error': invoice.status === 'error' }">
        <div class="col-12 mb-1" v-if="invoice.status === 'error'"><i class="icon-info-sign"></i> Virhe veloituksessa. Tarkasta maksukorttisi tiedot.</div>
        <div class="col-6 col-md-2 col-lg-2">{{ invoice.invoice_date | moment('D.M.Y') }}</div>
        <div class="col-6 col-md-2 col-lg-2">{{ invoice.invoice_number }}</div>
        <div class="col-12 col-md-6 col-lg-6">
          <template v-if="invoice.invoice_type === 'monthly_subscription'">
            <strong>Laskutuskausi:</strong> {{ invoice.subscription_period_start | moment('D.M.Y') }} - {{ invoice.subscription_period_end | moment('D.M.Y') }}<br/>
            {{ invoice.description }}
          </template>
          <template v-else-if="invoice.invoice_type === 'credit'">
            <strong>Hyvityslasku: </strong>{{ invoice.description }}
          </template>
          <template v-else>{{ invoice.description }}</template>
        </div>
        <div class="col-6 col-md-1 col-lg-1 text-md-right px-md-0">{{ invoice.total_amount }} €</div>
        <div class="col-6 col-md-1 col-lg-1 text-right">
          <i v-if="downloading == invoice.invoice_number" class="fa fa-spinner fa-spin pr-0"></i>
          <a class="cursor-pointer link" v-else @click="getReceipt(invoice.invoice_number)"><i class="text-red fa fa-download"></i></a>
        </div>
      </div>

    </template>
    <template v-else>
      Ei veloituksia
    </template>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'OrderItemInvoices',
  props: ['invoices'],

  data () {
    return {
      show: false,
      downloading: false
    }
  },

  computed: {
    ...mapState({
    }),

    getInvoices () {
      return _.filter(this.invoices, item => !item.deleted);
    }
  },

  methods: {
    getReceipt (invoiceNumber) {
      this.downloading = invoiceNumber;
      var self = this;
      this.$store.dispatch('order/getReceipt', { invoice_number: invoiceNumber }).catch(() => {
        this.$swal({
          title: 'Virhe kuitin latauksessa.',
          text: 'Kuitin latauksessa tapahtui virhe. Yritä hetken kuluttua uudelleen.'
        });
      }).finally(() => {
        self.downloading = null;
      });
    }
  }
}
</script>

