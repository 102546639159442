<template>
<section class="border-top pt-2 pt-lg-3 pb-2 pb-lg-3">
  <div class="row">
    <div class="col-12 col-md-3 mb-1 mb-md-0">
      <h3>Tilaukset</h3>
    </div>

    <div class="col-12 col-md-9">
      <div v-if="loading" class="ml-4 mt-2">
        <i class="fa fa-spinner fa-spin pr-0 text-lg"></i>
      </div>

      <div v-else-if="orders" v-for="(order, idx) in orders" :key="idx">
        <OrderItem :order="order" />
      </div>

    </div>
  </div>

</section>
</template>

<script>

import { mapState } from 'vuex';
import OrderItem from './partials/order/OrderItem';

export default {
  name: 'Orders',

  components: {
    OrderItem
  },

  computed: {
    ...mapState({
      orders: state => state.customer.orders
    })
  },

  data () {
    return {
      loading: true
    }
  },

  created () {
    this.$store.dispatch('customer/getOrders').finally(() => {
      this.loading = false;
    })
  }
}
</script>
