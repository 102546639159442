<template>
  <div class="fixed-bottom bg-white cart shadow" :class="{'cart-open': open}">

    <template v-if="!order">
      <div class="py-1 py-sm-2 px-1 text-center">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>

    <div v-else class="py-1 py-sm-2 px-2 page-container">
      <div class="row" @click="toggle" >

        <!-- Order info -->
        <div :class="{'col-10 col-md-8': !open, 'col-6 col-md-8': open}">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <i class="fa fa-shopping-cart text-md"></i>
            </div>
            <div>
              <template v-if="!open">
                <div>
                  <strong>{{ order_car.product_car.display_name }}</strong>
                  {{ order_car.price | value(0) }}{{'\xa0'}}€/kk
                </div>
                <div class="d-none d-sm-block">
                  <i class="icon-sm icon-calendar"></i> {{ order.start_date | moment('D.M.Y') }}
                  <i class="ml-1 fa fa-map-marker"></i> {{ order.dealer.display_name }}
                </div>
              </template>
              <template v-else>
                <h4>Tilauksesi</h4>
              </template>
            </div>
          </div>

        </div>

        <!-- Arrow button -->
        <div class="text-right" :class="{'col-2 col-md-4': !open, 'col-6 col-md-4': open}">
          <div class="cursor-pointer">
            <span v-if="!open" class="mr-1 d-none d-md-inline-block position-relative " style="top: -7px;">Avaa</span>
            <span v-else class="mr-1 position-relative " style="top: -7px;">Sulje</span>

            <i class="text-red text-lg" :class="{'icon-chevron-down': open, 'icon-chevron-up': !open}"></i>
          </div>
        </div>
      </div>

      <!-- Cart content -->
      <b-collapse id="collapse-cart" v-model="open">
        <div v-if="order" class="cart-content mt-2">
          <div class="row border-top cart-row">
            <div class="col-12 col-sm-4 cart-label">Automalli</div>
            <div class="col-12 col-sm-8 text-sm-right">{{ order_car.product_car.display_name}} {{ order.geartype ? '(' + order.geartype + ') ' : '' }}<strong>{{ order_car.price | value(0) }}{{'\xa0'}}€/kk</strong></div>
          </div>

          <div class="row border-top cart-row">
            <div class="col-12 col-sm-4 cart-label">{{ order.has_home_delivery ? 'Toimittaja': 'Noutopaikka' }}</div>
            <div class="col-12 col-sm-8 text-sm-right">
              {{ order.dealer.display_name }}<br/>
              {{ order.dealer.address }}, {{ order.dealer.zip }} {{ order.dealer.city }}
            </div>
          </div>

          <div class="row border-top cart-row">
            <div class="col-12 col-sm-4 cart-label">{{ order.has_home_delivery ? 'Toimituspäivä': 'Noutopäivä' }}</div>
            <div class="col-12 col-sm-8 text-sm-right">{{ order.start_date | moment('D.M.Y') }}</div>
          </div>

          <div class="row border-top border-bottom cart-row">
            <div class="col-12 col-sm-4 cart-label">Sopimusehdot</div>
            <div class="col-12 col-sm-8 text-sm-right">
              <a :href="contractTermsUrl" target="_blank" class="text-decoration-none text-red">Näytä sopimusehdot ></a>
            </div>
          </div>

          <div class="text-center mt-2 mb-2">
            <button @click="updateOrder" class="btn btn-sm btn-t1-function">
              Muokkaa tilaustasi
            </button>
          </div>

          <ExtraServices :cart="true" />
        </div>
      </b-collapse>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BCollapse } from 'bootstrap-vue'
import ExtraServices from '@/components/order/newOrder/ExtraServices'

export default {
  name: 'OrderCart',

  components: {
    ExtraServices,
    BCollapse
  },

  data () {
    return {
      open: false
    }
  },

  computed: {
    ...mapState({
      order: state => state.order.order,
      order_car: state => state.order.order.order_product_car,
      contractTermsUrl: state => state.contract_terms
    })
  },

  methods: {
    toggle () {
      this.open = !this.open;
    },

    updateOrder () {
      this.$bvModal.show('updateNewOrder');
    }
  }
}
</script>
