<template>
  <div class="payment-info">
    <OrderSteps :step="4"/>

    <template v-if="!order">
      <div class="text-center py-3">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>

    <template v-if="paymentCard">
      <div class="py-3 text-center card-info">
        <i class="icon-ok"></i>
        <h4 class="mt-2 mb-1">Maksukortin tiedot</h4>
        <p><strong>{{ paymentCard.issuer }}</strong> &nbsp; {{ paymentCard.masked_pan }} &nbsp; {{ paymentCard.expiry_month }}/{{ paymentCard.expiry_year }}</p>

        <button @click="finalizeOrder" class="mt-2 btn btn-lg btn-t1-primary">
            Viimeistele tilaus
          </button>
      </div>
    </template>

    <template v-else>
      <h4 class="mt-2 mb-1 text-center">Melkein valmista! Syötä vielä maksukorttisi tiedot.</h4>

      <div class="py-3 text-center">
        <img src="~@/assets/maksukortti.svg" />
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-md-7 text-center">
          <p class="mt-1 mb-3">Kuukausimaksu veloitetaan maksukortilta aikaisintaan 8 vrk ennen auton noutoa tai uuden tilausjakson alkamista. Kuukausimaksu veloitetaan syöttämältäsi maksukortilta automaattisesti.</p>

          <button @click="addCreditCard" class="btn btn-lg btn-t1-primary" :class="{'disabled': form.busy}">
            <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
            Lisää maksukortti
          </button>
        </div>
      </div>
    </template>

    <OrderCart v-if="order"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'
import OrderSteps from '@/components/order/newOrder/OrderSteps'
import OrderCart from '@/components/order/newOrder/OrderCart'


export default {
  name: 'PaymentInfo',

  components: {
    OrderSteps,
    OrderCart
  },

  data () {
    return {
      form: new Form({
        order_number: null
      })
    }
  },

  computed: {
    ...mapState({
      orderLoading: state => state.order.loading,
      order: state => state.order.order
    }),

    paymentCard () {
      if (!this.order || !this.order.payment_cards) return null;
      return this.order.payment_cards[0];
    }
  },

  methods: {
    addCreditCard () {
      this.form.order_number = this.order.order_number;
      this.$store.dispatch('order/addCreditCard', this.form).then(response => {
        if (response.data.terminal_url) {
          window.location.href = response.data.terminal_url;
        }
      }).catch(error => {
        console.log(error);
        this.$swal({
          title: 'Virhe maksukortin lisäyksessä',
          text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
        });
      });
    },

    finalizeOrder () {
      this.$router.push({ name: 'NewOrder.ConfirmOrder' });
    }
  },

  async mounted () {
    await this.$store.dispatch('order/getOrder');

    this.$store.dispatch('models/setModelDataLayer', this.order);
    if (!this.paymentCard && typeof this.$route.query.virhe !== 'undefined') {
      this.$swal({
        title: 'Virhe maksukortin lisäyksessä',
        text: 'Maksukortin lisäyksessä tapahtui virhe tai se peruutettiin. Ole hyvä ja yritä uudelleen.'
      });
    }
  }
}
</script>
