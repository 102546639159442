import Vue from 'vue'
import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    models: null,
    availability: null,
    selected: null,
    selectedStartDate: null,

    searchData: null,

    form: {
      location: '',
      models: [],
      distance: 100,
      gearType: ''
    }
  },

  mutations: {
    SET_VALUES: (state, payload) => {
      for (var key in payload) {
        if (Object.getOwnPropertyDescriptor(state, key)) {
          state[key] = payload[key];
        }
      }
    },

    SET_STARTDATE: (state, payload) => {
      if (state.selected) state.selected.startDate = payload;
    },

    RESET_FORM: (state) => {
      state.form = {
        location: '',
        models: [],
        distance: 100,
        gearType: ''
      }
    },

    UPDATE_FORM: (state, payload) => {
      for (var key in payload) {
        if (Object.getOwnPropertyDescriptor(state.form, key)) {
          state.form[key] = payload[key];
        }
      }
    }
  },

  actions: {
    resetAvailability ({ dispatch, commit }) {
      commit('SET_VALUES', { availability: null });
    },

    async locateUser ({ dispatch, commit }) {
      try {
        var coordinates = await this._vm.$getLocation({
          enableHighAccuracy: false,
          timeout: Infinity,
          maximumAge: 0
        });

        if (!coordinates.lat) return Promise.reject(new Error('Location not found'));

        var location = await Vue.axios.post(process.env.VUE_APP_BACKEND_API + '/order/locateUser', {
          lat: coordinates.lat,
          lng: coordinates.lng
        });
        return Promise.resolve(location.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async checkAvailability ({ dispatch, commit }, form) {
      try {
        commit('SET_VALUES', { availability: null });
        commit('SET_VALUES', { selected: null });
        commit('UPDATE_FORM', {
          location: form.location,
          models: form.models
        });
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/order/getModelAvailability');
        if (response.data.models && response.data.models.length > 0) {
          commit('SET_VALUES', {
            availability: _.sortBy(response.data.models, item => { return parseInt(item.distanceFromUser) }),
            searchData: response.data.search
          });
        } else {
          commit('SET_VALUES', {
            availability: []
          });
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      };
    },

    selectModel ({ dispatch, commit, state }, item) {
      commit('SET_VALUES', {
        selected: {
          make: item.car.make,
          model: item.car.model,
          gearType: item.gearType,
          locationID: item.locationID,
          startDate: item.date
        }
      });
    },

    selectStartDate ({ dispatch, commit, state }, date) {
      commit('SET_STARTDATE', date);
    },

    setModelDataLayer ({ dispatch }, item) {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        malli: item.order_number ? item.order_product_car.product_car.display_name : item.car.display_name,
        jm: item.dealer.display_name,
        paikkakunta: item.dealer.city
      });
    }
  }
}
