<template>
  <div>
    <template v-if="!order || orderLoading">
      <div class="text-center py-3">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>

    <template v-else>
      <div class="row justify-content-center">
        <div class="col-12 col-md-7 text-center">

          <h2 class="mt-2">Kiitos tilauksesta!</h2>
          <p class="mt-2">Lähetimme sähköpostiisi tilausvahvistuksen ja ohjeet auton noutoa varten. Vahvista sähköpostiosoitteesi sähköpostissa olevasta linkistä.</p>
          <p>Luo seuraavaksi salasana alla olevasta painikkeesta ja jatka Oma Toyota Kuukausitilaus -sivuillesi.</p>


          <div class="mt-3" v-if="!customer.user_password_set">
            <button @click="submit" class="btn btn-lg btn-t1-primary">
              Luo tilillesi salasana
            </button>
          </div>

          <div class="mt-3" v-else>
            <router-link class="btn btn-lg btn-t1-primary" :to="{ name: 'Customer' }">Siirry tilisivulle</router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrderComplete',

  computed: {
    ...mapState({
      orderLoading: state => state.order.loading,
      order: state => state.order.order,
      customer: state => state.customer.customer
    })
  },

  methods: {
    submit () {
      this.$router.push({ name: 'NewOrder.SetPassword' });
    }
  },

  async mounted () {
    await this.$store.dispatch('order/redirectNewOrder');
  }
}
</script>
