<template>
  <div>

    <template v-if="!order">
      <div class="text-center py-3">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>

    <template v-else>
      <div class="row justify-content-center">
        <div class="col-12 col-md-7 text-center">
          <template v-if="!customer.phone">
            <h2 class="mt-2">Syötä puhelinnumerosi ja tilaa</h2>

            <p class="mt-2">Tarvitsemme vielä puhelinnumerosi, niin voimme olla sinuun tarvittaessa yhteydessä.</p>

            <fieldset class="form-group my-2" :class="{'validation-error': form.errors.has('phone')}">
              <input type="text" class="form-control" id="phone" v-model="form.phone" placeholder="Puhelinnumerosi">
              <small class="validation-error--message">{{ form.errors.get('phone') }}</small>
            </fieldset>
            <h3 class="mt-2">Vahvista tilauksesi</h3>
          </template>

          <template v-else>
            <h2 class="mt-2">Vahvista tilauksesi</h2>
          </template>

          <p class="mt-3 mb-3">Kun vahvistat tilauksen, veloitamme maksukorttiasi aikaisintaan 8 vuorokautta ennen noutoa ja sen jälkeen aina ennen uuden tilauskauden alkamista. Näet tilauskaudet ja tulevat veloituspäivät omalta sivultasi, jossa voit myös peruuttaa tilauksesi.</p>

          <div>
            <button @click="submit" class="btn btn-lg btn-t1-primary" :class="{'disabled': form.busy}">
              <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
              Vahvista tilaus
            </button>
          </div>

          <!-- TODO
          <div class="mt-2">Minulle suositteli palvelua...</div>
          -->
        </div>
      </div>

      <OrderSummary />
      <OrderCart />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'
import OrderSummary from '@/components/order/newOrder/OrderSummary'
import OrderCart from '@/components/order/newOrder/OrderCart'

export default {
  name: 'ConfirmOrder',

  components: {
    OrderSummary,
    OrderCart
  },

  computed: {
    ...mapState({
      orderLoading: state => state.order.loading,
      order: state => state.order.order,
      customer: state => state.customer.customer
    })
  },

  data () {
    return {
      form: new Form({
        phone: null
      })
    }
  },

  methods: {
    submit () {
      this.$store.dispatch('order/confirmOrder', this.form).then(() => {
        // Make sure Order complete event is sent
        this.$gtm.trackView('NewOrder.OrderComplete', '/uusi-tilaus/valmis');
        this.$router.push({ name: 'NewOrder.OrderComplete' });
      }).catch(error => {
        if (error.response.status === 404) {
          this.$swal({
            title: 'Virhe tilauksen vahvistuksessa',
            text: 'Tilausta ei löytynyt tai se on vanhentunut.'
          });
        } else if (error.response.status === 422) {
          if (error.response.data.errors.phone) {
            this.form.errors.set('phone', 'Syötäthän puhelinnumerosi vahvistaaksesi tilauksen.');
          }
        }
      });
    }
  },

  async mounted () {
    await this.$store.dispatch('order/redirectNewOrder');
  }
}
</script>
