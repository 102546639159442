<template>
  <div class="page">
    <div class="page-container py-2 px-1 bg-white">
       <router-view></router-view>
    </div>

    <ModalUpdateNewOrder />
  </div>
</template>

<script>
import ModalUpdateNewOrder from '@/components/order/modals/ModalUpdateNewOrder'

export default {
  name: 'NewOrder',

  components: {
    ModalUpdateNewOrder
  },

  created () {
    // Redirect if parent route called
    if (this.$route.name === 'NewOrder') {
      this.$store.dispatch('order/redirectNewOrder');
    }
  }
}
</script>
