<template>
<div class="bg-grey-transparent" v-if="askPickupTime">
  <div class="row py-2 px-2">
    <div class="col-12 ">
      <h4><i class="text-green icon-info-sign"></i> Auto on noudettavissa {{ order.start_date | moment('D.M.Y') }}</h4>

      <p class="mt-1 mb-0">Haen auton klo.:
        <button class="btn btn-md no-mw" @click="setPickup(8)">8.00-10.00</button>
        <button class="btn btn-md no-mw" @click="setPickup(10)">10.00-12.00</button>
        <button class="btn btn-md no-mw" @click="setPickup(12)">12.00-14.00</button>
        <button class="btn btn-md no-mw" @click="setPickup(14)">14.00-16.00</button>
      </p>
    </div>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'OrderItemPickupTime',
  props: ['order'],

  data () {
    return {
      form: new Form({
        order_number: this.order.order_number,
        pickup_time: null
      })
    }
  },

  computed: {
    ...mapState({
    }),

    askPickupTime () {
      return this.order.status === 'confirmed' && !this.order.pickup_time && !this.order.has_home_delivery && this.order.vehicle_release_ready;
    }
  },

  methods: {
    setPickup (time) {
      this.form.pickup_time = time;
      this.$store.dispatch('order/setPickupTime', this.form).then(() => {
        this.$store.dispatch('customer/getOrders');
        this.$swal({
          title: 'Noutoaika valittu!',
          icon: 'success'
        });
      }).catch(() => {
        this.$swal({
          title: 'Virhe noutoajan valinnassa',
          icon: 'error',
          text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
        });
      });
    }
  }
}
</script>

