import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth';
import _ from 'lodash';
import order from './order';
import models from './models';
import customer from './customer';
import searchAgent from './searchAgent';
import router from './../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    init: false,
    contract_terms: null,
    disabled_order_dates: {
      to: null,
      from: null,
      dates: null
    },
    available_cities: []
  },

  mutations: {
    SET_INIT: (state, payload) => {
      state.contract_terms = payload.contract_terms;
      // Parse disabled dates
      state.disabled_order_dates.to = Vue.moment(payload.disabled_order_dates.to).toDate();
      state.disabled_order_dates.from = Vue.moment(payload.disabled_order_dates.from).toDate();
      state.disabled_order_dates.dates = _.map(payload.disabled_order_dates.dates, date => {
        return Vue.moment(date).toDate();
      });
      state.available_cities = payload.cities;
      state.init = true;
    }
  },

  actions: {
    /**
     * Initializes application
     * @param {*} param0
     */
    async initApplication ({ dispatch, commit }) {
      try {
        var init = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/init');
        commit('models/SET_VALUES', { models: init.data.cars })
        commit('auth/SET_LOGGED_IN', init.data.user)
        commit('customer/SET_CUSTOMER', init.data.customer)

        // Load search agent if exists
        if (router.history.current.name === 'SearchAgent' && router.history.current.params.token) {
          try {
            var searchAgent = await dispatch('searchAgent/get', router.history.current.params.token);
            searchAgent.gearType = searchAgent.gearType || '';
            commit('models/SET_VALUES', { form: searchAgent });
            commit('searchAgent/SET_FORM', searchAgent);
          } catch (error) {
            // Search agent expired/removed/not found? Do nothing.
          }
        }

        commit('SET_INIT', init.data);

        this._vm.$eventBus.$emit('applicationInitialized');

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },

  modules: {
    auth,
    order,
    models,
    customer,
    searchAgent
  }
})
