import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: require('@/components/views/Index').default,
    meta: {
      gtm: 'Tilaussivu'
    }
  },

  {
    path: '/hakuvahti/:token',
    name: 'SearchAgent',
    component: require('@/components/views/Index').default,
    meta: {
      gtm: 'Hakuvahti - tilaussivu'
    }
  },

  {
    path: '/hakuvahti/poista/:token',
    name: 'SearchAgentDelete',
    component: require('@/components/views/searchAgent/Delete').default,
    meta: {
      gtm: 'Hakuvahti - poisto'
    }
  },

  {
    path: '/uusi-tilaus',
    name: 'NewOrder',
    component: require('@/components/views/newOrder/NewOrderTemplate').default,
    meta: {
      requiresAuth: true
    },

    children: [
      {
        path: 'lisapalvelut',
        name: 'NewOrder.SelectExtraServices',
        component: require('@/components/views/newOrder/SelectExtraServices').default
      },
      {
        path: 'tunnistautuminen',
        name: 'NewOrder.Identify',
        component: require('@/components/views/newOrder/Identify').default
      },

      {
        path: 'maksutiedot',
        name: 'NewOrder.PaymentInfo',
        component: require('@/components/views/newOrder/PaymentInfo').default
      },

      {
        path: 'vahvista',
        name: 'NewOrder.ConfirmOrder',
        component: require('@/components/views/newOrder/ConfirmOrder').default
      },

      {
        path: 'valmis',
        name: 'NewOrder.OrderComplete',
        component: require('@/components/views/newOrder/OrderComplete').default
      },

      {
        path: 'luo-salasana',
        name: 'NewOrder.SetPassword',
        component: require('@/components/views/newOrder/SetPassword').default
      }
    ]
  },

  {
    path: '/tili',
    name: 'Customer',
    component: require('@/components/views/customer/CustomerTemplate').default,
    meta: { requiresAuth: true }
  },

  {
    path: '/kirjaudu',
    name: 'Login',
    component: require('@/components/views/auth/Login').default
  },

  {
    path: '/kirjaudu/:token',
    name: 'LoginWithToken',
    component: require('@/components/views/auth/LoginWithToken').default
  },

  {
    path: '/salasana-unohtunut',
    name: 'ForgotPassword',
    component: require('@/components/views/auth/ForgotPassword').default
  },

  {
    path: '/vaihda-salasana/:token',
    name: 'ResetPassword',
    component: require('@/components/views/auth/ResetPassword').default
  },

  {
    path: '*',
    component: require('@/components/views/404').default
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(m => m.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      store.dispatch('auth/fetchMe').then(() => {
        next();
      }).catch(() => {
        next({ name: 'Login' });
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
