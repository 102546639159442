<template>
  <div class="page">
    <div class="page-container px-sm-1">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 text-center">
          <div v-if="loading" class="ml-4 mt-2">
            <i class="fa fa-spinner fa-spin pr-0 text-lg"></i>
          </div>

          <template v-else>
            <h3 class="mb-2 pt-2">Haluatko poistaa hakuvahdin?</h3>
            <button class="btn btn-t1-primary mr-0 mr-sm-1 mb-1" @click="saveForm" :class="{'disabled': form.busy}">
              <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
              Kyllä
            </button>

            <button class="btn btn-t1-default m-0 mb-1" @click="cancel()">
              En
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vform'

export default {
  name: 'SearchAgentDelete',

  data () {
    return {
      loading: true,

      form: new Form({
        token: null
      })
    }
  },

  methods: {
    saveForm () {
      this.form.token = this.$route.params.token;
      this.$store.dispatch('searchAgent/delete', this.form).then(() => {
        this.$swal({
          title: 'Hakuvahti poistettu',
          icon: 'success'
        }).then(() => {
          this.$router.push({ name: 'Index' });
        });
      }).catch(() => {
        this.$swal({
          title: 'Virhe hakuvahdin poistossa',
          text: 'Hakuvahti on saattanut vanheta, joten se on jo poistettu.',
          icon: 'error'
        });
      });
    },

    cancel () {
      this.$router.push({ name: 'Index' });
    }
  },

  mounted () {
    this.$store.dispatch('searchAgent/get', this.$route.params.token).then(() => {
      this.loading = false;
    }).catch(() => {
      this.$swal({
        title: 'Hakuvahtia ei löytynyt',
        text: 'Hakuvahti on saattanut vanheta, joten se on jo poistettu.',
        icon: 'error'
      }).then(() => {
        this.$router.push({ name: 'Index' });
      });
    })
  }
}
</script>
