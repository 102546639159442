<template>
<section>
  <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center">
        <h2>Luo Oma Toyota Kuukausitilaus salasana</h2>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-12 col-md-6 text-center">


        <p class="mt-2">Jatkaaksesi Oma Toyota Kuukausitilaus -sivulle, luo ensin itsellesi salasana, jolla voit jatkossa kirjatua palveluun. Sähköpostiosoitteesi toimii käyttäjätunnuksena.</p>
        <p>Salasanan tulee sisältää vähintään 10 merkkiä, yhden ison kirjaimen ja yhden numeron.</p>

        <div v-on:keyup.enter="submitPassword">
          <fieldset class="form-group my-2 text-left" :class="{'validation-error': form.errors.has('password')}">
            <label for="password">Salasana</label>
            <input type="password" class="form-control" id="password" v-model="form.password">
            <small class="validation-error--message">{{ form.errors.get('password') }}</small>
          </fieldset>

          <fieldset class="form-group my-2 text-left" :class="{'validation-error': form.errors.has('password_confirmation')}">
            <label for="password_confirmation">Salasana uudelleen</label>
            <input type="password" class="form-control" id="password_confirmation" v-model="form.password_confirmation">
            <small class="validation-error--message">{{ form.errors.get('password_confirmation') }}</small>
          </fieldset>

          <div>
            <button @click="submitPassword" class="btn btn-lg btn-t1-primary" :class="{'disabled': form.busy}">
              <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
              Luo salasana
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'CustomerSetPassword',

  data () {
    return {
      form: new Form({
        password: null,
        password_confirmation: null
      })
    }
  },

  computed: {
    ...mapState({
      init: state => state.init,
      customer: state => state.customer.customer,
      user: state => state.auth.me
    })
  },

  methods: {
    submitPassword () {
      var self = this;
      this.$store.dispatch('order/setPassword', this.form).then(async function () {
        await self.$swal({
          icon: 'success',
          title: 'Salasana luotu',
          text: 'Siirry Oma Toyota Kuukausitilaus –sivulle, josta voit hallinnoida tilaustasi.',
          confirmButtonText: 'Siirry tilisivulle'
        });

        self.$store.dispatch('auth/fetchMe');
      }).catch(error => {
        if (error.response.status === 404) {
          this.$swal({
            icon: 'error',
            title: 'Virhe salasanan luonnissa',
            text: 'Lataa sivu uudelleen ja yritä uudelleen.'
          });
        }
      });
    }
  }
}
</script>
