<template>
  <div class="page">
    <div class="page-container pb-3 px-sm-1 bg-white">
      <OrderSteps :step="1"/>
      <CheckAvailability />
    </div>
  </div>
</template>

<script>
import OrderSteps from '@/components/order/newOrder/OrderSteps'
import CheckAvailability from '@/components/order/availability/CheckAvailability'

export default {
  name: 'Index',

  components: {
    OrderSteps,
    CheckAvailability
  }
}
</script>
