<template>
  <div class="page">
    <div class="login bg-white py-3 px-1">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3 class="bold">Kirjaudutaan sisään</h3>

            <div class="pt-3" v-if="form.busy">
              <i class="fa fa-spinner fa-spin pr-0 text-lg"></i>
            </div>

            <div class="pt-2" v-else-if="invalid">
              <p class="text-red bold">
                Kirjautumislinkkisi on vanhentunut.
              </p>
              <p>Ota yhteys ylläpitoon ja pyydä uusi kirjautumislinkki tai kirjaudu sisään <router-link :to="{name: 'Login'}">tästä.</router-link></p>
              <p>Jos olet unohtanut salasanasi, voit tilata uuden <router-link :to="{name: 'ForgotPassword'}">tästä.</router-link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vform'
import { mapState } from 'vuex'

export default {
  name: 'LoginWithToken',

  computed: {
    ...mapState({
      authenticated: state => state.auth.logged_in
    })
  },

  data () {
    return {
      invalid: false,
      form: new Form({
        token: ''
      })
    }
  },

  methods: {
    async login () {
      if (this.authenticated) {
        await this.$store.dispatch('auth/logout');
      }

      this.form.fill(this.$route.params);
      this.$store.dispatch('auth/loginWithToken', this.form).then(() => {
        this.redirectCustomer();
      }).catch(() => {
        this.invalid = true;
      });
    },

    redirectCustomer () {
      this.$store.dispatch('customer/redirectCustomer');
    }
  },

  created () {
    this.form.busy = true;
    this.$eventBus.$on('applicationInitialized', this.login);
  },

  beforeDestroy () {
    this.$eventBus.$off('applicationInitialized', this.login);
  }
}
</script>
2
