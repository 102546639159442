<template>
<div class="border-top py-2 px-2">
  <h4 class="cursor-pointer link" @click="show = !show">Maksukortit
    <i class="float-right text-red" :class="{'icon-chevron-down': !show, 'icon-chevron-up': show}"></i>
  </h4>

  <div v-if="show" class="mt-1 invoices">
    <template v-if="order.payment_cards && order.payment_cards.length">
      <div class="row pb-1 d-none d-md-flex border-bottom">
        <div class="col-12 col-md-2 col-lg-2 support-text">Aktiivinen</div>
        <div class="col-12 col-md-6 col-lg-6 support-text">Maksukortti</div>
        <div class="col-12 col-md-2 col-lg-2 support-text">Voimassa</div>
        <div class="col-12 col-md-2 col-lg-2 text-md-right support-text">Poista</div>
      </div>


      <div class="row border-bottom invoice-row" v-for="(card, idx) in order.payment_cards" :key="idx">
        <div class="col-12 col-md-2 col-lg-2">
          <template v-if="card.active">
            <i class="icon-ok ml-md-2"></i>
            <span class="d-md-none ml-1">Aktiivinen</span>
          </template>
          <a class="cursor-pointer link" v-else @click="setActive(card.id)" :class="{'disabled': form.busy}">
            <span class="text-red">Aktivoi </span>
            <span class="text-red d-md-none">tämä maksukortti</span>
          </a>
        </div>
        <div class="col-12 col-md-6 col-lg-6">{{ card.issuer }} {{ card.masked_pan }}</div>
        <div class="col-12 col-md-2 col-lg-2">{{ card.expiry_month }}/{{ card.expiry_year }}</div>
        <div class="col-12 col-md-2 col-lg-2 text-md-right" v-if="!card.active">
          <i v-if="deleting === card.id" class="fa fa-spinner fa-spin pr-0"></i>
          <a class="cursor-pointer link" v-else @click="deleteCard(card.id)" :class="{'disabled': form.busy}">
            <i class="text-red icon-remove d-none d-md-inline-block"></i>
            <span class="text-red d-md-none">Poista maksukortti</span>
          </a>
        </div>
      </div>
    </template>

    <template v-else>
      Maksukortteja ei lisätty
    </template>

    <div class="mt-2">
      <button @click="showAddModal" class="btn btn-md btn-t1-function">Lisää uusi maksukortti</button>
    </div>
  </div>

  <ModalAddPaymentCard :order="order"/>
</div>
</template>

<script>
import ModalAddPaymentCard from './ModalAddPaymentCard'
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'OrderItemPaymentCards',
  props: ['order'],

  components: {
    ModalAddPaymentCard
  },

  data () {
    return {
      show: false,
      deleting: false,

      form: new Form({
        order_number: this.order.order_number,
        card_id: null
      })
    }
  },

  computed: {
    ...mapState({
    })
  },

  methods: {
    showAddModal () {
      this.$bvModal.show('addPaymentCard-' + this.order.order_number);
    },

    deleteCard (id) {
      this.form.card_id = id;
      this.$store.dispatch('order/deletePaymentCard', this.form).then(() => {
        this.$store.dispatch('customer/getOrders');
        this.$swal({
          title: 'Maksukortti poistettu',
          icon: 'success'
        });
      }).catch(() => {
        this.$swal({
          title: 'Virhe maksukortin poistossa.',
          icon: 'error',
          text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
        });
      });
    },

    setActive (id) {
      this.form.card_id = id;
      this.$store.dispatch('order/setActivePaymentCard', this.form).then(() => {
        this.$store.dispatch('customer/getOrders');
      }).catch(() => {
        this.$swal({
          title: 'Virhe maksukortin asetuksessa.',
          icon: 'error',
          text: 'Ole hyvä, päivitä sivu ja yritä uudelleen.'
        });
      });
    }
  }
}
</script>
