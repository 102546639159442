import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    form: null
  },

  mutations: {
    SET_FORM: (state, payload) => {
      state.form = payload;
    }
  },

  actions: {
    async save ({ dispatch, commit }, form) {
      try {
        await form.post(process.env.VUE_APP_BACKEND_API + '/searchAgent/save');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      };
    },

    async get ({ dispatch, commit }, token) {
      try {
        var response = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/searchAgent/get/' + token);
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      };
    },

    async delete ({ dispatch, commit }, form) {
      try {
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/searchAgent/delete');
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      };
    }
  }
}
