<template>
  <section class="select-extras">
    <div class="container" v-if="extraServices && extraServices.length">
      <div class="row">
        <template v-if="cart">
          <div class="col-12 mb-1 mb-sm-2 px-0">
            <h5>Saatavilla olevat lisäpalvelut</h5>
          </div>
        </template>
        <template v-else>
          <div class="col-12 mt-3 mb-2 text-center">
            <h3>Saatavilla olevat lisäpalvelut</h3>
          </div>
        </template>

        <div class="col-12" :class="{'px-0': cart, 'text-center bg-grey-light py-2': !cart}">
          <ExtraServiceItem
            :class="{'mx-1': !cart, 'mr-1': cart}"
            v-for="(service, idx) in extraServices"
            :key="idx"
            :item="service"
            :parentId="getUniqueId"/>
        </div>
      </div>
    </div>

    <div v-if="hasHomeDelivery" class="row border-bottom mt-1 py-1" :class="{'order-content': !cart}">
      <div class="col-12 col-sm-8">
        <h5>Kotiinkuljetusosoite:</h5>
        {{ hasHomeDelivery.additional_info.address }}, {{ hasHomeDelivery.additional_info.zip }} {{ hasHomeDelivery.additional_info.city }}<br/>
        Toimitusaikatoive: {{ hasHomeDelivery.additional_info.delivery_time }}
      </div>

      <div class="col-12 col-sm-4 text-sm-right mt-1 mt-sm-0">
        <button @click="$eventBus.$emit('editExtraService-' + getUniqueId, 'home_delivery')" class="btn btn-sm">Muokkaa</button>
      </div>

    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ExtraServiceItem from '@/components/order/newOrder/ExtraServiceItem'

export default {
  name: 'ExtraServices',

  props: {
    cart: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  components: {
    ExtraServiceItem
  },

  computed: {
    getUniqueId () {
      return this._uid
    },

    ...mapState({
      extraServices: state => state.order.order ? state.order.order.available_extra_services : null,
      selectedExtraServices: state => state.order.selected_extra_services
    }),

    hasHomeDelivery () {
      if (!this.extraServices || !this.selectedExtraServices.length) return null;
      return (this.selectedExtraServices.find(el => el.service_key === 'home_delivery'));
    }
  }
}
</script>
