<template>
<div class="border-top py-2 px-2" v-if="getServices.length">
  <h4 class="cursor-pointer link" @click="show = !show">Lisäkilometripaketit
    <i class="float-right text-red" :class="{'icon-chevron-down': !show, 'icon-chevron-up': show}"></i>
  </h4>

  <div v-if="show" class="mt-1 invoices">
    <template v-if="getServices.length">
      <div class="row mb-1">
        <div class="order-1 col-6 col-md-2 support-text">Ostopvm</div>
        <div class="order-3 order-md-2 col-12 col-md-8 support-text">Paketti</div>
        <div class="order-2 order-md-3 col-6 col-md-2 text-right support-text">Hinta</div>
      </div>

      <div class="row border-top invoice-row" v-for="(item, idx) in getServices" :key="idx">
        <div class="order-1 col-6 col-md-2 ">{{ item.created_at | moment('D.M.Y') }}</div>
        <div class="order-3 order-md-2 col-12 col-md-8 ">{{ item.service_name }}</div>
        <div class="order-2 order-md-3 col-6 col-md-2 text-right">{{ item.price | value(2) }} €</div>
      </div>

    </template>
    <template v-else>
      Ei ostettuja paketteja
    </template>

    <div class="mt-2">
      <button @click="showModal" class="btn btn-md btn-t1-primary">Osta lisäkilometripaketti</button>
    </div>
  </div>
</div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'OrderItemExtraServices',
  props: ['extraServices', 'orderNumber'],

  data () {
    return {
      show: false,
      downloading: false
    }
  },

  computed: {
    getServices () {
      return _.filter(this.extraServices, item => item.sale_phase === 'after_sale');
    }
  },

  methods: {
    showModal () {
      this.$bvModal.show('purchaseExtraServices-' + this.orderNumber);
    }
  }
}
</script>

