<template>
  <div class="available-models pt-1" :class="{'disabled': formBusy}">
    <div class="result" >
      <!-- No cars found -->
      <template v-if="!availability.length">
        <div class="row mb-3 justify-content-center">
          <div class="col-12 col-md-6 text-center">
            <h3>Voi ei! Emme löytäneet yhtään vapaata autoa. Kokeile valita jokin toinen automalli</h3>
          </div>
        </div>

        <SearchAgent v-if="!update" />
      </template>

      <!-- No cars found in range -->
      <template v-else-if="!getDistanceFiltered.length">
        <div class="row mb-3">
          <div class="col-12 text-center">
            <h3>Voi ei! Emme löytäneet yhtään vapaata autoa läheltäsi. <br/>Haluatko etsiä kauempaa kuin
              <div class="d-inline-block select-filter-wrapper ">
                <select v-model="filterDistance" class="select-filter text-red primary">
                  <option v-for="(val, idx) in filterDistanceOptions" :key="idx" :value="val">
                    {{ val }} km
                  </option>
                </select>
              </div>
             ?</h3>
            <p class="mt-2">Kokeile myös valita jokin toinen automalli.</p>
          </div>
        </div>

        <SearchAgent v-if="!update"/>
      </template>

      <template v-else>
        <div class="row">
          <div class="col-12 text-center">
            <p>Tutustumishinnat ovat voimassa ensimmäisen kuukauden ajan, jonka jälkeen tilaus jatkuu normaalihintaisena.</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">

            <fieldset class="form-group d-inline-block result-filter">
              <label for="">Vaihteisto</label>
              <select v-model="filterGearType" >
                <option v-for="(val, idx) in filterGearTypeOptions" :key="idx" :value="val.value">
                  {{ val.label }}
                </option>
              </select>
            </fieldset>

            <fieldset class="form-group d-inline-block result-filter">
              <label for="">Etäisyys</label>
              <select v-model="filterDistance" >
                <option v-for="(val, idx) in filterDistanceOptions" :key="idx" :value="val">
                  {{ val }} km
                </option>
              </select>
            </fieldset>

            <fieldset class="form-group d-inline-block result-filter">
              <label for="">Järjestys</label>
              <select v-model="order_by" >
                <option v-for="(val, idx) in filterOrderOptions" :key="idx" :value="val.value">
                  {{ val.label }}
                </option>
              </select>
            </fieldset>


          </div>
        </div>


        <div class="pt-2">

          <!-- Cars not found with current gearType selection -->
          <div v-if="!showModels.length" class="row justify-content-center mt-1 mb-2">
            <div class="col-12 col-md-8 text-center">
              Emme löytäneet yhtään valintojesi mukaista autoa. <br/>Päivitä hakuehtojasi ja hae uudelleen
            </div>
          </div>

          <!-- Available car item list -->
          <div v-else class="row justify-content-center">
            <AvailableItem v-for="(item, idx) in showModels" :key="idx" :item="item" :update="update" />
          </div>

          <!-- Show more button -->
          <div v-if="getFiltered.length > showModels.length" class="row px-1 py-1 text-center">
            <div class="col-12 text-center">
              <button @click="showMoreRows" class="btn btn-chevron"><i class="icon icon-chevron-down"></i> Näytä lisää </button>
            </div>
          </div>

          <!-- Show further button -->
          <div v-else-if="getGearTypeFiltered.length > showModels.length" class="row px-1 py-1 text-center">
            <div class="col-12 text-center">
              <button @click="showFurther" class="btn btn-chevron"><i class="icon icon-chevron-down"></i> Näytä autoja kauempaa </button>
            </div>
          </div>
        </div>

        <SearchAgent class="mt-2" v-if="!update && !showModels.length"/>

      </template>
    </div>

    <div v-if="formBusy" class="form-loading px-2 py-3 text-center no-bg">
      <div class="mb-1"><i class="fa fa-spinner fa-spin pr-0 text-lg"></i></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash'
import AvailableItem from '@/components/order/availability/AvailableItem';
import SearchAgent from '@/components/order/searchAgent/SearchAgent';

export default {
  name: 'CheckAvailabilityResult',
  components: {
    AvailableItem,
    SearchAgent
  },

  props: {
    update: {
      type: Boolean,
      default: false,
      required: false
    },

    formBusy: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data () {
    return {
      show_rows: 6,
      order_by: 'distance'
    }
  },

  computed: {
    ...mapState({
      form: state => state.models.form,
      availability: state => state.models.availability
    }),

    // ------------------------------------------
    // Store values
    // ------------------------------------------
    filterDistance: {
      get () { return this.form.distance },
      set (value) { this.$store.commit('models/UPDATE_FORM', { distance: value }) }
    },

    filterGearType: {
      get () { return this.form.gearType },
      set (value) { this.$store.commit('models/UPDATE_FORM', { gearType: value }) }
    },

    // ------------------------------------------
    // Select options
    // ------------------------------------------
    filterDistanceOptions () {
      return [100, 200, 500, 1000];
    },

    filterOrderOptions () {
      return [
        { value: 'distance', label: 'Etäisyys' },
        { value: 'price', label: 'Malli' },
        { value: 'date', label: 'Noutopäivä' }
      ];
    },

    filterGearTypeOptions () {
      return [
        { value: '', label: 'Kaikki' },
        { value: 'A', label: 'Automaatti' },
        { value: 'M', label: 'Manuaali' }
      ];
    },

    // ------------------------------------------
    // Model listing
    // ------------------------------------------
    getOrdered () {
      return _.sortBy(this.availability, item => {
        if (this.order_by === 'price') return parseFloat(item.car.list_price);
        else if (this.order_by === 'date') return new Date(item.date);
        return parseInt(item.distanceFromUser);
      });
    },

    getDistanceFiltered () {
      return this.getOrdered.filter(item => item.distanceFromUser <= this.filterDistance);
    },

    getGearTypeFiltered () {
      return this.getOrdered.filter(item => this.filterGearType !== '' ? item.gearType === this.filterGearType : true);
    },

    getFiltered () {
      return this.getDistanceFiltered.filter(item => this.filterGearType !== '' ? item.gearType === this.filterGearType : true);
    },

    showModels () {
      return this.getFiltered.slice(0, this.show_rows);
    }
  },

  watch: {
    availability (newValue, oldValue) {
      this.show_rows = 6;
    }
  },

  methods: {
    showMoreRows () {
      this.show_rows = 999;
    },

    showFurther () {
      this.filterDistance = 1000;
    }
  }
}
</script>
