<template>
  <div class="page">
    <div class="page-container py-3 bg-white px-2 text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 text-center">
          <h2 class="mb-2 pt-2">Etsimääsi sivua ei löytynyt</h2>
          <p>Etsimääsi sivua ei valitettavasti löytynyt tai se on poistettu. Tarkista sivun osoite tai siirry <router-link to="/">etusivulle.</router-link></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>
