<template>
  <div class="px-1 py-2">
    <iframe name="E-Ident"
      :src="url"
      height="512"
      width="100%">
    </iframe>
  </div>
</template>

<script>

export default {
  name: 'EIdentIframe',
  props: ['url']
}
</script>

<style lang="scss" scoped>
  iframe {
    border: none;

    .ipage {
      text-align: center;
    }
  }
</style>
