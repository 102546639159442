import Vue from 'vue'
import router from '@/router/'

export default {
  namespaced: true,

  state: {
    customer: null,
    orders: null
  },

  mutations: {
    SET_CUSTOMER: (state, payload) => {
      state.customer = payload;
    },

    SET_ORDERS: (state, payload) => {
      state.orders = payload;
    }
  },

  getters: {
    hasOrderInProgress: state => state.customer && (state.customer.latest_order_status === 'pending' || state.customer.latest_order_status === 'expired')
  },

  actions: {
    async deleteOrderInProgress ({ dispatch }, orderNumber) {
      try {
        await Vue.axios.post(process.env.VUE_APP_BACKEND_API + '/customer/deleteOrderInProgress', {
          order_number: orderNumber || null
        });
        await dispatch('getCustomer');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /*
    async createCustomer ({ dispatch, commit }, form) {
      try {
        // Create new order
        var response = await form.post(process.env.VUE_APP_BACKEND_API + '/customer/create');

        // Set token and fetch user info
        Token.setToken(response.data.token);
        await dispatch('auth/fetchMe', null, { root: true });

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    */

    async getCustomer ({ dispatch, commit }) {
      try {
        var response = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/customer/getCustomer');
        commit('SET_CUSTOMER', response.data.data);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateCustomerInfo (context, form) {
      try {
        await form.post(process.env.VUE_APP_BACKEND_API + '/customer/updateCustomerInfo');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updatePassword (context, form) {
      try {
        await form.post(process.env.VUE_APP_BACKEND_API + '/customer/updatePassword');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getOrders ({ dispatch, commit }) {
      try {
        var response = await Vue.axios.get(process.env.VUE_APP_BACKEND_API + '/customer/getOrders');
        commit('SET_ORDERS', response.data.data);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /**
     * Redirects user to right page after login
     */
    redirectCustomer ({ state, dispatch, getters }) {
      if (!state.customer.orders) {
        router.push({ name: 'Customer' });
      } else if (getters.hasOrderInProgress) {
        // Redirect to new order page
        dispatch('order/redirectNewOrder', null, { root: true });
      } else {
        // Redirect to customer page
        router.push({ name: 'Customer' });
      }
    }
  }
}
