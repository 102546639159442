<template>
  <b-modal
    ref="createCustomer"
    id="createCustomer"
    size="md"
    title=""
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :hide-footer="true"
    >

    <template v-if="modal_state === 'new'">
      <h3 class="mb-2">Jes! Mahtavaa, että haluat mukaan!</h3>
      <p>Annathan seuraavaksi sähköpostiosoitteesi, jotta voimme lähettää sinulle linkin. Tästä linkistä voit myöhemmin jatkaa tilausta, jos et halua viedä sitä vielä maaliin. </p>
      <p>Et tässä vaiheessa sitoudu mihinkään.</p>

      <fieldset class="form-group mt-3" :class="{'validation-error': form.errors.has('email')}">
        <label for="email" class="bold">Sähköpostiosoitteesi</label>
        <input type="email" class="form-control" id="email" v-model="form.email">
        <small class="validation-error--message">{{ form.errors.get('email') }}</small>
      </fieldset>

      <fieldset class="form-group" :class="{'validation-error': form.errors.has('email_confirmation')}">
        <label for="email_confirmation" class="bold">Vahvista sähköpostiosoitteesi</label>
        <input type="email" class="form-control" id="email_confirmation" v-model="form.email_confirmation">
        <small class="validation-error--message">{{ form.errors.get('email_confirmation') }}</small>
      </fieldset>

      <div class="checkbox">
        <input id="accept_terms" type="checkbox" v-model="form.accept_terms">
        <label class="ws-normal" for="accept_terms">Hyväksyn Toyota-verkkosivujen käyttöehdot ja tietosuojakäytännöt</label>
      </div>

      <p>Annettuja henkilötietoja käytetään vain Toyota Kuukausitilaus -palvelun hallinnoimiseen.<br/>
      <a class="cursor-pointer" @click="show_terms = !show_terms">
        <template v-if="!show_terms"><i class="icon-chevron-down"></i> <u>Näytä ehdot</u></template>
        <template v-else><i class="icon-chevron-up"></i> <u>Piilota ehdot</u></template>
      </a>
      </p>


      <div v-if="show_terms">
        <p>Toyota Auto Finland Oy, Korpivaarantie 1, 01450 Vantaa, kerää rekisterinpitäjänä henkilötietoja Toyota Kuukausitilaus -palvelun asiakkaista ja uutiskirjetilaajista. Kun lähetät yhteydenoton tämän sivun kautta, yhteydenottoasi koskevat seuraavat säännöt:</p>

        <ul>
          <li>Käytämme näitä henkilötietoja vain pyyntöjesi toteuttamiseen ja seurantaan.</li>
          <li>Edellä mainitut ehdot täydentävät Toyotan yleistä tietosuojakäytäntöä, jossa kuvataan henkilötietojen käsittelyn yleiset suuntaviivat ja jotka sinun on luettava ja hyväksyttävä.</li>
        </ul>
      </div>

      <div class="text-center pt-1 pb-3">
        <button class="btn btn-lg btn-t1-primary" @click="createNewOrder" :class="{'disabled': form.busy || !form.accept_terms}">
          <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
          Jatka
        </button>
      </div>
    </template>

    <template v-else-if="modal_state === 'login'">
      <p>Antamallasi sähköpostiosoitteella on jo tehty tilaus.</p>
      <p>Klikkaa alla olevasta linkistä kirjautuaksesi sisään omalle sivullesi. Pääset siellä hallinnoimaan tilauksiasi ja tekemään halutessasi uuden tilauksen.</p>
      <p>Jos et muista salasanaasi, voit tilata salasanan vaihtolinkin <router-link :to="{name: 'ForgotPassword'}">tästä</router-link>.</p>

      <div class="text-center pt-1 pb-3">
        <button class="btn" @click="modal_state = 'new'">Peruuta</button>
        <router-link :to="{name: 'Login'}" class="btn btn-t1-primary">Kirjaudu sisään</router-link>
      </div>
    </template>

    <template v-else-if="modal_state === 'continue'">
      <h3 class="mb-2">Tervetuloa jatkamaan tilaustasi!</h3>
      <p>Olet jo aiemmin aloittanut tilauksen tekemisen tällä sähköpostiosoitteella. Klikkaa alla olevasta painikkeesta niin lähetämme sähköpostiisi viestin, josta voit jatkaa tilauksen tekemistä aiemmin syöttämilläsi tiedoilla.</p>

      <div class="text-center pt-1 pb-3">
        <button class="btn" @click="modal_state = 'new'">Peruuta</button>
        <button class="btn btn-t1-primary" @click="sendLoginToken" :class="{'disabled': form.busy}">
          <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
          Lähetä sähköposti
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'ModalCreateCustomer',

  data () {
    return {
      error: null,

      modal_state: 'new',

      form: new Form({
        email: null,
        email_confirmation: null,
        accept_terms: false
      }),

      show_terms: false
    }
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    })

  },

  methods: {
    createNewOrder () {
      this.error = null;

      this.$store.dispatch('order/createNewCustomerWithOrder', this.form).then(() => {
        this.form.reset();
        this.$bvModal.hide('createCustomer');

        // Goto new order page
        this.$router.push({ name: 'NewOrder' });
      }).catch(error => {
        switch (error.response.status) {
          case 500:
            this.error = 'Lomakkeen lähetyksessä oli virheitä. Yritä myöhemmin uudelleen.';
            break;
          case 410:
            this.error = 'Valitsemaasi autoa ei ole enää saatavilla haluamastasi noutopisteestä.';
            break;
          case 400:
            this.error = 'Lomakkeen lähetyksessä oli virheitä. Yritä myöhemmin uudelleen.';
            break;
          case 423:
            this.error = 'Ordering is currently disabled. Please try again later.';
            break;
          case 409:
            if (error.response.data.error === 'exists') {
              if (error.response.data.data.password_set) {
                this.modal_state = 'login';
              } else {
                this.modal_state = 'continue';
              }
              break;
            }
        }

        if (this.error) {
          this.$swal({
            title: 'Virhe lähetyksessä',
            text: this.error
          });
        }
      })
    },

    sendLoginToken () {
      this.$store.dispatch('order/sendLoginToken', this.form).then(() => {
        this.$swal(
          'Sähköposti lähetetty',
          'Ohjeet tilauksen jatkamiseksi on lähetetty sähköpostiisi.',
          'success'
        )
      }).catch(() => {
        this.$swal({
          title: 'Virhe lähetyksessä',
          text: 'Sähköpostin lähetyksessä tapahtui virhe. Yritäthän uudelleen.',
          icon: 'error'
        });
      })
    }
  }
}
</script>"
