<template>
  <div :class="getColClass">
    <div class="bg-grey-light available-item">

      <!-- Title/price -->
      <div class="row title">
        <div class="col-6">
          <h3>{{ item.car.display_name }}</h3>
          <p class="support-text">{{ getGearType }}</p>
        </div>
        <div class="col-6 text-right">
          <h3>{{ item.car.list_price | value(0) }} €/kk</h3>
          <p class="support-text">(norm. {{ item.car.retail_price | value(0) }} €/kk)</p>
        </div>
      </div>

      <div class="align-bottom">
        <!-- Car image -->
        <div class="row pt-0 pt-sm-1">
          <div class="col-12 text-center" style="height: 120px;">

            <img class="mh-100" :src="item.car.image_sm" :alt="item.car.display_name" />

          </div>
        </div>

        <!-- Dealer -->
        <div class="row pt-0 pt-sm-1">
          <div class="col-12">
            <div class="pl-2 position-relative">
              <i class="position-absolute fa fa-map-marker" style="left: 0; top: 5px;"></i>
              <strong>{{ item.distanceFromUser }} km</strong>
              <p class="support-text bold">{{ item.dealer.display_name }}</p>
              <p class="support-text">{{ item.dealer.address }}, {{ item.dealer.zip }} {{ item.dealer.city }}</p>
            </div>
          </div>
        </div>

        <!-- Dates -->
        <div class="row pt-1">
          <div class="col-12">
            <div class="border-top pt-1">
              <div class="pl-2 position-relative">
                <i class="position-absolute fa fa-calendar" style="left: 0; top: 5px;"></i>
                <p>Noudettavissa alkaen <strong>{{ item.date | moment('D.M.Y') }}</strong></p>
              </div>
            </div>
          </div>
        </div>

        <!-- Select/continue button -->
        <div class="row">
          <div class="col-12 text-center">
            <button class="btn btn-block btn-t1-primary text-center" @click="selectModel">
              {{ update ? 'Valitse' : 'Jatka tilaamaan' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AvailableItem',

  props: {
    item: {
      required: true
    },

    update: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    ...mapState({
      selectedModel: state => state.models.selected
    }),

    getColClass () {
      return this.update ? 'col-12 col-lg-6 col-xl-4 mb-2' : 'col-12 col-sm-6 col-lg-4 mb-2';
    },

    getGearType () {
      return this.item.gearType.toLowerCase() === 'a' ? 'Automaatti' : 'Manuaali';
    },

    getExtraServices () {
      if (!this.item.extraServices.length) return null;

      var services = {};

      // Group extra services by service_key
      for (var i in this.item.extraServices) {
        var es = this.item.extraServices[i];
        if (!services[es.service_key]) services[es.service_key] = es;
      }

      return services;
    }
  },

  methods: {
    selectModel () {
      this.$store.dispatch('models/selectModel', this.item);
      this.$store.dispatch('models/setModelDataLayer', this.item);
      // Emit event to parent
      this.$eventBus.$emit('selectModel');
    }
  }
}
</script>
