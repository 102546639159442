<template>
  <div>
    <OrderSteps :step="2"/>

    <template v-if="orderLoading || !order">
      <div class="text-center py-3">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>

    <template v-else>
    <OrderService />

      <template v-if="!isExpired">
        <ExtraServices />

        <h3 class="text-center mt-3">Sopimusehdot</h3>
        <div class="text-center mt-2">
          <button class="btn btn-t1-secondary text-black" @click="form.accept_terms = !form.accept_terms">
            <i class="fa" style="width: 30px;" :class="{'fa-square-o':!form.accept_terms, 'fa-check text-red': form.accept_terms}"></i>
            Hyväksyn sopimusehdot
          </button>
          <a :href="contractTermsUrl" target="_blank" class="text-decoration-none text-red text-nowrap mt-1 mt-sm-0 ml-sm-2 d-block d-sm-inline">Tutustu sopimusehtoihin ></a>
        </div>

        <div class="text-center mt-2">
          <button @click="acceptTerms" class="btn btn-lg btn-t1-primary" :class="{'disabled': !form.accept_terms}">
            Jatka tunnistautumiseen
          </button>
        </div>

        <OrderCart />
      </template>
    </template>
  </div>
</template>

<script>
import { Form } from 'vform'
import { mapState } from 'vuex';
import _ from 'lodash';
import OrderSteps from '@/components/order/newOrder/OrderSteps'
import OrderCart from '@/components/order/newOrder/OrderCart'
import OrderService from '@/components/order/newOrder/OrderService'
import ExtraServices from '@/components/order/newOrder/ExtraServices'

export default {
  name: 'SelectExtraServices',

  components: {
    OrderSteps,
    OrderService,
    ExtraServices,
    OrderCart
  },

  computed: {
    ...mapState({
      orderLoading: state => state.order.loading,
      order: state => state.order.order,
      customer: state => state.customer.customer,
      contractTermsUrl: state => state.contract_terms
    }),

    isExpired () {
      return this.order && (this.order.status === 'expired' || this.order.status === 'removed');
    }
  },

  data () {
    return {
      form: new Form({
        accept_terms: false
      })
    }
  },

  methods: {
    acceptTerms () {
      this.$store.dispatch('order/acceptTermsAndServices', this.form).then(() => {
        this.$router.push({ name: 'NewOrder.Identify' });
      });
    }
  },

  async mounted () {
    var redirect = await this.$store.dispatch('order/redirectNewOrder');
    if (redirect) return;

    if (this.isExpired) {
      var msg = {
        title: 'Tervetuloa jatkamaan tilaustasi!',
        text: 'Ups! Ehdit olla sen aikaa poissa, että jouduimme vapauttamaan varauksesi muille asiakkaille. Ei hätää, tästä pääset uudestaan hakemaan autoja.',
        confirmButtonText: 'Hae autoja'
      };

      // If user has multiple orders, show profile page link
      if (this.customer.orders > 1) {
        msg = _.merge(msg, {
          showCancelButton: true,
          cancelButtonText: 'Siirry tilisivulle'
        });
      }

      this.$swal(msg).then(result => {
        if (!result.isConfirmed) {
          this.$router.push({ name: 'Customer' });
        }
      });
    }
  }
}
</script>
