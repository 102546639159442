<template>
<section>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-6 text-center mt-1">
        <h4>Anna paikkakunta tai osoite, jonka läheltä haluat etsiä autoja, sekä valitse sinua kiinnostavat automallit.</h4>
        <a class="d-inline-block mt-1 cursor-pointer underline" @click="showAvailableCities">Näytä lista kaupungeista, joissa on noutopiste.</a>
      </div>
    </div>

    <template v-if="init">
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-lg-10 col-xl-10">
          <CheckAvailabilityForm />
         </div>
      </div>

      <div v-if="availability" class="row justify-content-center mt-3">
        <div class="col-12">
          <CheckAvailabilityResult :formBusy="busy" />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="text-center py-3">
        <i class="fa fa-spinner fa-spin pr-0 mr-1 text-lg"></i>
      </div>
    </template>
  </div>

  <ModalCreateCustomer />
</section>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import CheckAvailabilityForm from '@/components/order/availability/CheckAvailabilityForm';
import CheckAvailabilityResult from '@/components/order/availability/CheckAvailabilityResult';
import ModalCreateCustomer from '@/components/order/modals/ModalCreateCustomer'

export default {
  name: 'CheckAvailability',
  components: {
    CheckAvailabilityForm,
    CheckAvailabilityResult,
    ModalCreateCustomer
  },

  computed: {
    ...mapState({
      init: state => state.init,
      availability: state => state.models.availability
    }),

    ...mapGetters({
      hasOrderInProgress: 'customer/hasOrderInProgress'
    })
  },

  data () {
    return {
      busy: false
    }
  },

  methods: {
    showAvailableCities () {
      this.$bvModal.show('availableCities');
    },

    async createNewOrder () {
      this.busy = true;

      // Check user status
      try {
        var isUser = await this.$store.dispatch('auth/fetchMe');
      } catch (error) {
        isUser = false;
      }

      if (isUser) {
        if (this.hasOrderInProgress) {
          var confirm = await this.$swal({
            title: 'Haluatko jatkaa keskeneräistä tilausta?',
            text: 'Sinulta löytyi vanha keskeneräinen tilaus. Haluatko jatkaa vanha tilauksen tekemistä vai tehdä uuden tilauksen?',
            showCancelButton: true,
            cancelButtonText: 'En, tee uusi tilaus',
            confirmButtonText: 'Jatkan vanhaa tilausta'
          });

          if (confirm.isConfirmed) {
            // Continue existing order
            this.$store.dispatch('order/redirectNewOrder');
            return;
          } else {
            // Delete old existing order and continue new
            await this.$store.dispatch('customer/deleteOrderInProgress');
          }
        }

        //
        // Create new order
        //
        this.$store.dispatch('order/createNewOrder').then(() => {
          // Goto new order page
          this.$router.push({ name: 'NewOrder' });
        }).catch(error => {
          var errorMsg = null;
          switch (error.response.status) {
            case 410:
              errorMsg = 'Valitsemaasi autoa ei ole enää saatavilla haluamastasi noutopisteestä.';
              break;
            default:
              errorMsg = 'Lomakkeen lähetyksessä oli virheitä. Yritä myöhemmin uudelleen.';
          }

          this.$swal({
            title: 'Virhe lähetyksessä',
            text: errorMsg
          });
        }).finally(() => {
          this.busy = false;
        });
      } else {
        //
        // Open create customer modal for new user
        //
        this.$bvModal.show('createCustomer');
        this.busy = false;
      }
    }
  },

  created () {
    this.$store.dispatch('models/resetAvailability');
    this.$eventBus.$on('selectModel', this.createNewOrder);
  },

  beforeDestroy () {
    this.$eventBus.$off('selectModel', this.createNewOrder);
  }
}
</script>
