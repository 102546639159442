<template>
  <div class="page">
    <div class="login bg-white py-3 px-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3 class="bold">Kirjaudu sisään</h3>
          </div>
        </div>
        <div v-if="form.errors.has('error')" class="bg-message p-1 mt-2 error normal">{{ form.errors.get('error') }}</div>

        <div v-on:keyup.enter="login">
          <div class="row mt-2">
            <div class="col-12">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('email') }">
                <label for="email">Sähköpostiosoite</label>
                <input v-model="form.email" type="email" class="form-control" id="email">
                <small class="text-muted validation-error--message">{{ form.errors.get('email') }}</small>
              </fieldset>
            </div>

            <div class="col-12">
              <fieldset class="form-group" :class="{'validation-error': form.errors.has('password') }">
                <label for="password">Salasana</label>
                <input v-model="form.password" type="password" class="form-control" id="password">
                <small class="text-muted validation-error--message">{{ form.errors.get('password') }}</small>
              </fieldset>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12 col-sm-6 mb-1">
              <button @click="login" class="btn btn-t1-primary" :class="{'disabled': form.busy}"><i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>Kirjaudu</button>
            </div>
            <div class="col-12 col-sm-6 text-sm-right mt-1">
              <router-link :to="{name: 'ForgotPassword'}">Salasana unohtunut?</router-link>
            </div>
          </div>
        </div>

        <div class="row border-top mt-2 pt-2">
          <div class="col-12">
            Uusi asiakas? Tee tilaus <router-link to="/">tästä!</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form } from 'vform'
import { mapState } from 'vuex';

export default {
  name: 'Login',

  data () {
    return {
      form: new Form({
        email: '',
        password: '',
        device_name: 'tkt-ui'
      })
    }
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    })
  },

  methods: {
    login () {
      this.$store.dispatch('auth/login', this.form).then(() => {
        this.redirect();
      });
    },

    redirect () {
      if (!this.customer) return;

      this.$store.dispatch('customer/redirectCustomer');
    }
  },

  created () {
    this.$store.dispatch('auth/fetchMe').then(() => {
      // Logged in
      this.redirect();
    }).catch(() => {
      // Do nothing
    });
  }
}
</script>
