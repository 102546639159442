<template>
  <div class="availability-form">
    <div class="form-data" v-on:keyup.enter="submitForm" >
      <div class="form-body" :class="{'form-disabled': form.busy}">
        <!-- Location -->
        <div class="row">
          <div class="col-12">
            <div class="form-inline justify-content-center" :class="{'validation-error': form.errors.has('location')}">
              <label for="location">Sijainti</label>
              <input type="text" class="form-control mx-2" id="location" placeholder="" v-model="form.location">
              <div>
                <a @click="locate" class="cursor-pointer" :class="{ 'disabled': locating }">
                  <i v-if="locating" class="fa fa-spinner fa-spin"></i>
                  <i v-else class="icon-map-marker"></i>
                  Paikanna sijaintini
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Model select -->
        <div class="row mt-2">
          <div class="col-12 text-center">
            <a class="btn btn-sm btn-model-select mb-1 mt-0" v-for="(model, idx) in getModels" :key="idx" :class="{'model-selected': isSelected(model.model)}" @click="selectModel(model.model)">
              <i v-if="isSelected(model.model)" class="fa fa-lg fa-check-square-o"></i>
              <i v-else class="fa fa-lg fa-square-o"></i>
              {{ model.display_name }}
            </a>
          </div>
        </div>

        <!-- Action buttons -->
        <div class="row mt-2" id="car-search-action">
          <div class="col text-center">
            <button @click="submitForm" class="btn btn-t1-function" :class="{'disabled': form.busy}">
              <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
              <template v-if="availability || update">
                Päivitä haku
              </template>
              <template v-else>
                Hae
              </template>
            </button>
          </div>
        </div>
      </div>

      <!-- Busy overlay -->
      <div v-if="form.busy" class="form-loading px-2 py-3">
        <div class="mb-1"><i class="fa fa-spinner fa-spin pr-0 text-lg"></i></div>
        <div>Odotathan hetken. Tarkistamme mitä autoja meillä on vapaana. Tässä voi mennä muutama sekunti.</div>
      </div>
    </div>

    <ModalAvailableCities />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'
import ModalAvailableCities from './../modals/ModalAvailableCities'


export default {
  name: 'CheckAvailabilityForm',
  components: {
    ModalAvailableCities
  },

  props: {
    update: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data () {
    return {
      locating: false,
      form: new Form({
        location: '',
        coordinates: null,
        models: []
      })
    }
  },

  computed: {
    ...mapState({
      modelsForm: state => state.models.form,
      models: state => state.models.models,
      order: state => state.order.order,
      customer: state => state.customer.customer,
      availability: state => state.models.availability,
      searchAgent: state => state.searchAgent.form,
      searchData: state => state.models.searchData
    }),

    getModels () {
      return [
        {
          display_name: 'Kaikki',
          model: 0
        }
      ].concat(this.models)
    },

    isSelected () {
      return model => this.form.models.indexOf(model) !== -1 || (model === 0 && !this.form.models.length);
    }
  },

  methods: {
    selectModel (model) {
      // Select all
      if (model === 0) {
        this.form.models = [];
        return;
      }

      // Push/remove if exists
      var index = this.form.models.indexOf(model);
      if (index === -1) this.form.models.push(model);
      else this.form.models.splice(index, 1);
    },

    locate () {
      this.locating = true;
      this.$store.dispatch('models/locateUser').then(response => {
        this.form.location = response.route + ' ' + response.street_number + ', ' + response.postal_code + ' ' + response.city;
      }).catch(() => {
        this.$swal({
          title: 'Virhe paikannuksessa',
          text: 'Varmista, että olet sallinut paikannuksen ja yritä hetken kuluttua uudelleen.'
        });
      }).finally(() => {
        this.locating = false;
      });
    },

    submitForm () {
      if (!this.form.location) {
        this.form.errors.set('location', 'Syötä sijainti');
        return;
      }

      this.$store.dispatch('models/checkAvailability', this.form).then(() => {
        // GTM events
        const models = this.form.models.length ? this.form.models : ['Kaikki'];
        models.forEach(model => {
          this.$gtm.trackEvent({
            event: 'carSearch',
            category: 'Toyota Kuukausitilaus',
            action: 'click',
            label: 'Autojen saatavuushaku',
            value: model
          });
        });

        this.$gtm.trackEvent({
          category: 'Toyota Kuukausitilaus',
          action: 'zip',
          label: 'Postinumero tai kaupunki',
          value: (this.searchData.geocode.postal_code + ' ' + this.searchData.geocode.city).trim()
        });

        this.$scrollTo('.available-models', 500);
      }).catch(error => {
        if (error.response.status !== 422) {
          var title = 'Virhe saatavuuskyselyssä';
          var msg = 'Saatavuuskyselyssä tapahtui odottamaton virhe. Yritäthän hetken kuluttua uudelleen.';

          if (error.response.data && error.response.data === 'Location not found') {
            title = 'Emme löytäneet sijaintiasi'
            msg = 'Tarkasta, että sijantisi on kirjoitettu oikein tai kokeile paikanna sijaintini -nappia.'
          }

          this.$swal({
            icon: 'error',
            title: title,
            text: msg
          });
        }
      });
    },

    initSearchForm () {
      if (typeof this.$route.query.malli !== 'undefined') {
        // Set model from query parameter
        this.form.models = [this.$route.query.malli];
      } else if (this.searchAgent) {
        // Submit searchAgent
        this.form.models = this.searchAgent.models;
        this.form.location = this.searchAgent.location;
        this.form.coordinates = {
          lat: this.searchAgent.location_lat,
          lng: this.searchAgent.location_lng
        }
        this.submitForm();
      } else if (this.order || this.customer) {
        // Set date from existing order/customer
        if (this.order) this.form.models = [this.order.order_product_car.product_car.model];
        if (this.customer) this.form.location = this.customer.post_office || '';
      }
    }
  },

  mounted () {
    this.initSearchForm();
  }
}
</script>
