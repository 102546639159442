export default (value, decimals, thousandSeparator) => {
  decimals = typeof decimals !== 'undefined' ? decimals : 2;
  thousandSeparator = typeof thousandSeparator !== 'undefined';
  value = parseFloat(value);
  if (value === 0) decimals = 0;
  value = value.toFixed(decimals).replace('.', ',')
  if (thousandSeparator) value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return value;
}
