<template>
<div class="bg-grey-transparent" v-if="showSection">
  <div class="row py-2 px-2">
    <div class="col-12 ">
      <h4><i class="text-red icon-info-sign mr-1"></i>
        <template v-if="order.status === 'cancelled'">Tilauksesi on päättynyt {{ order.cancel_date | moment('D.M.Y') }}</template>
        <template v-else-if="order.status === 'under_cancellation'">Tilauksesi on päättymässä {{ order.cancel_date | moment('D.M.Y') }}</template>
      </h4>
      <!--
      <template v-if="order.is_continuable">
        <p class="mt-1">Jos kuitenkin haluat jatkaa tilausta, voit tehdä sen vielä 14 päivää ennen tilauksen päättymispäivää.</p>
        <button class="btn btn-md btn-t1-function" @click="continueOrder">Jatka tilausta</button>
      </template>
      -->
    </div>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'OrderItemPickupTime',
  props: ['order'],

  data () {
    return {
      form: new Form({
        order_number: this.order.order_number
      })
    }
  },

  computed: {
    ...mapState({
    }),

    showSection () {
      return this.order.status === 'under_cancellation' || this.order.status === 'cancelled';
    }
  },

  methods: {
    continueOrder () {
      this.$store.dispatch('order/continueOrder', this.form).then(() => {
        this.$store.dispatch('customer/getOrders');
        this.$swal({
          title: 'Tilauksesi on merkitty jatkettavaksi!',
          icon: 'success'
        });
      }).catch(() => {
        this.$swal({
          title: 'Virhe tilauksen jatkamisessa',
          icon: 'error',
          text: 'Ole hyvä, päivitä sivu ja yritä uudelleen. Jos jatkaminen ei onnistu vieläkään, ole hyvä ja ota yhteyttä ylläpitoon.'
        });
      });
    }
  }
}
</script>

