<template>
  <b-modal
    :ref="modalId"
    :id="modalId"
    size="md"
    title="Tilauksen päättäminen"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :header-class="'no-title'"
    :footer-class="'justify-content-center'"
    >

    <div>
      <h4 class="mb-1">Haluatko varmasti päättää tilauksen?</h4>
      <p>Voit päättää tilauksesi kaksi viikkoa ennen tilauskauden päättymistä. Kuluva tilauskausi päättyy {{ order.billing_cycle.end | moment('D.M.Y') }}, joten sopimuksesi viimeinen päivä ja autosi palautuspäivä on <strong>{{ order.cancelling_date | moment('D.M.Y') }}</strong>. Saat tilauksen päättämisen jälkeen auton palauttamisesta tarkemmat ohjeet sähköpostiisi.</p>
      <p>Muistathan, että voit tilauksen päättymisen jälkeen milloin tahansa ilmoittautua uudelleen Toyota Kuukausitilaus -palvelun käyttäjäksi! </p>
    </div>


    <template v-slot:modal-footer="{ ok, cancel}">
      <button class="btn btn-t1-default" @click="cancel(false)">
        Takaisin
      </button>

      <button class="btn btn-t1-primary" @click="cancelOrder" :class="{'disabled': form.busy}">
        <i v-if="form.busy" class="fa fa-spinner fa-spin pr-0 mr-1"></i>
        Päätä tilaus
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { Form } from 'vform'

export default {
  name: 'ModalCancelOrder',

  props: ['order'],

  data () {
    return {
      error: null,

      form: new Form({
        order_number: ''
      })
    }
  },

  computed: {
    ...mapState({
      customer: state => state.customer.customer
    }),

    modalId () {
      return 'cancelOrder-' + this.order.order_number;
    }
  },

  methods: {
    cancelOrder () {
      this.form.order_number = this.order.order_number;

      this.$store.dispatch('order/cancelOrder', this.form).then(response => {
        this.$swal({
          title: 'Tilauksesi on merkitty päättyväksi.',
          icon: 'success'
        });
        this.cancel(true);
      }).catch(() => {
        this.$swal({
          title: 'Virhe tilauksen peruutuksessa',
          icon: 'error',
          text: 'Ole hyvä, päivitä sivu ja yritä uudelleen. Jos tilauksen päättäminen ei onnistu vieläkään, ole hyvä ja ota yhteyttä ylläpitoon.'
        });
      });
    },

    cancel (reload) {
      if (reload) {
        this.$store.dispatch('customer/getOrders');
      }
      this.$bvModal.hide(this.modalId);
    }
  }
}
</script>
